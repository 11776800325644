import { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import img_header from "../img/header.jpg";
import Web3 from "web3";

import {
  toggleActiveSession,
  toggleActiveChoosing,
  randomNumber,
} from "../helpers/Admin";

function ModalAdmin({
  currentSession,
  currentStage,
  wallet,
  GOFPContract,
  showModal,
  handleModalClose,
  numberOfDoors,
}) {
  const [selectedDoor, setSelectedDoor] = useState(null);
  const [randomDoor, setRandomDoor] = useState("???");
  const [doorRevealed, setDoorRevealed] = useState(false);

  const [doorLockButtonText, setDoorLockButtonText] = useState(null);
  const [disableDoorLockButton, setDisableDoorLockButton] = useState(false);
  const [disableDoorConfirmButton, setDisabledDoorConfirmButton] =
    useState(false);

  useEffect(() => {
    if (!currentSession) return;

    if (currentSession.isChoosingActive) {
      setDoorLockButtonText("Disable");
    } else {
      setDoorLockButtonText("Enable");
    }
  }, [currentSession]);

  const handleDoorSelect = (doorID) => {
    if (doorID === selectedDoor) {
      setSelectedDoor(null);
    } else {
      setSelectedDoor(doorID);
    }
  };

  const handleDoorLockToggle = async () => {
    setDisableDoorLockButton(true);
    let _sessionID = currentSession.sessionID;
    await toggleActiveChoosing(GOFPContract, wallet, _sessionID);
    setDoorLockButtonText(null);
    setDisableDoorLockButton(false);
  };

  const handleSessionToggle = async () => {
    // setDisableDoorLockButton(true);
    let _sessionID = currentSession.sessionID;
    toggleActiveSession(GOFPContract, wallet, _sessionID);
  };

  const confirmDoorSelect = async (doorID) => {
    if (!currentSession || !currentStage) return;

    setDisabledDoorConfirmButton(true);

    try {
      const _doorConfirm = await GOFPContract.methods
        .setCorrectPathForStage(
          currentSession.sessionID,
          currentStage,
          doorID,
          true
        )
        .send({ from: wallet?.provider?.selectedAddress });
      console.log("_doorConfirm: ", _doorConfirm);
    } catch (error) {
      console.log("Error: ", error);
    }

    setDisabledDoorConfirmButton(false);
  };

  // const createSession = async () => {
  //   await GOFPContract.methods
  //     .createSession(
  //       "0x8819CFdb4Fd6Ba0464Ef2283F5F621443B7eC2F4",
  //       "0x4Df452487E6c9d0C3Dc5EB4936244F8572b3F0b6",
  //       Web3.utils.toBN(25000000000000000000),
  //       true,
  //       "https://dev.cryptounicorns.fun/",
  //       [2, 2],
  //       false
  //     )
  //     .send({
  //       from: wallet?.accounts[0]?.address,
  //     });
  // };

  Math.easeOutExpo = function (t, b, c, d) {
    return c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
  };

  // const handleRandomDoorReveal = (numberOfDoors) => {
  //   setDoorRevealed(false);

  //   const LOOPS = 100; // How many loops do we want
  //   let _doorChoicesArr = [];

  //   let CORRECT_DOOR = randomNumber(1, numberOfDoors); // TODO: Pull from BC

  //   // Build door options, ending with correct door
  //   let _doorChoice = CORRECT_DOOR;
  //   _doorChoicesArr.push(CORRECT_DOOR);
  //   for (let i = 0; i < LOOPS; i++) {
  //     _doorChoice--;

  //     if (_doorChoice < 1) _doorChoice = numberOfDoors;

  //     _doorChoicesArr.push(_doorChoice);
  //   }

  //   // for loop throws warning due to _doorID declared outside
  //   _doorChoicesArr.reduce((prev, current, index) => {
  //     let v = Math.easeOutExpo(index, 0, 1, 100);

  //     setTimeout(() => {
  //       // console.log(index);
  //       setRandomDoor(_doorChoicesArr[index]);
  //     }, (1 - v) * 10000);

  //     // End of loop. Fire end animation
  //     if (index === LOOPS - 1) {
  //       setTimeout(() => {
  //         setDoorRevealed(true);
  //       }, v * 10000);
  //     }

  //     return current;
  //   }, 0);
  // };

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      size="xl"
      centered
      className="no-footer">
      <Modal.Header closeButton className="justify-content-center py-3">
        <img className="bg-header" src={img_header} alt="" />
        <Container>
          <Row>
            <Col>
              <h2>Gamemaster Tools</h2>
              <h5 className="font-primary">
                With great power comes great responsibility
              </h5>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="d-flex justify-content-center">
            <Button
              variant="secondary"
              className="btn-small mt-2 mb-3 mx-2"
              disabled={disableDoorLockButton || !doorLockButtonText}
              onClick={() => handleDoorLockToggle()}>
              {disableDoorLockButton || !doorLockButtonText
                ? "Updating..."
                : `${doorLockButtonText} Door Selection`}
              <div className="subtitle">
                Doors currently{" "}
                {currentSession?.isChoosingActive ? "enabled" : "disabled"}
              </div>
            </Button>
            {/* remove this from live, for testing only */}
            {/* <Button
              className="btn btn-primary btn-small mt-2 mb-3 mx-2"
              onClick={() => createSession()}>
              Create Chapter
              <div className="subtitle">
                Chapter {currentSession?.sessionID}
              </div>
            </Button> */}
          </Col>
        </Row>
        {numberOfDoors > 0 && (
          <Row className="align-items-center">
            {/* <Col
              md={{ span: 6, offset: 0 }}
              className={`text-center my-4 admin-door-reveal ${
                doorRevealed ? "revealed" : ""
              }`}>
              <h3>Correct Door</h3>
              <div className="door-reveal-number">
                <h1 className="jumbo my-3">{randomDoor}</h1>
              </div>
              <Button
                disabled={currentSession?.isChoosingActive}
                className="btn btn-secondary btn-small mt-2 mb-3 mx-2"
                onClick={() => handleRandomDoorReveal(numberOfDoors)}>
                Reveal correct door!
              </Button>
            </Col> */}
            <Col
              md={{ span: 12, offset: 0 }}
              className="admin-door-select-wrapper d-flex flex-column align-items-center">
              {[...Array.from(Array(numberOfDoors).keys(), (n) => n + 1)].map(
                (doorID) => {
                  return (
                    <Button
                      key={doorID}
                      disabled={currentSession?.isChoosingActive}
                      onClick={() => handleDoorSelect(doorID)}
                      className={`admin-door-select ${
                        selectedDoor === doorID ? "selected" : ""
                      }`}>
                      <h3 className="m-0">
                        {selectedDoor === doorID ? "Selected" : "Select"} Door{" "}
                        {doorID}
                      </h3>
                    </Button>
                  );
                }
              )}
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center pt-3 pb-2">
        <img className="bg-footer" src={img_header} alt="" />
        <Container className="d-flex align-items-center justify-content-center">
          <Row>
            <Col>
              <Button
                disabled={
                  !selectedDoor ||
                  currentSession?.isChoosingActive ||
                  disableDoorConfirmButton
                }
                className="btn btn-primary btn-primary-ingame"
                // onClick={() => setRevealDoorID(1)}
                onClick={() => confirmDoorSelect(selectedDoor)}>
                {currentSession?.isChoosingActive
                  ? "Lock Doors to Select"
                  : `Confirm Door ${selectedDoor ? selectedDoor : ""}`}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAdmin;
