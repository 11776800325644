import { useEffect } from "react";
import Web3 from "web3";

export const POLYGON_NETWORK = process.env.REACT_APP_POLYGON_NETWORK;
export const CONNECT_YOUR_WALLET = "Connect your wallet";

const switchNetwork = async (wallet, chainId, connectedChain) => {
  let web3 = new Web3(wallet.provider);
  if (connectedChain !== chainId) {
    try {
      await web3.currentProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(chainId) }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        alert("add this chain id");
      }
    }
  }
};

const updateWalletStatus = (wallet, setWalletStatus, CONNECT_YOUR_WALLET) => {
  setWalletStatus(
    wallet?.accounts[0]?.address
      ? `Wallet Connected: ${wallet?.accounts[0]?.address.slice(
          0,
          6
        )}...${wallet?.accounts[0]?.address.slice(-4)}`
      : CONNECT_YOUR_WALLET
  );
};

export const WalletHook = (
  wallet,
  netconfig,
  connectedChain,
  setWalletStatus
) => {
  useEffect(() => {
    if (!wallet) return;
    switchNetwork(wallet, netconfig.networkId, connectedChain);
  }, [wallet, connectedChain, netconfig.networkId]);

  useEffect(() => {
    if (!wallet?.accounts[0]?.address) {
      setWalletStatus(CONNECT_YOUR_WALLET);
    } else {
      updateWalletStatus(wallet, setWalletStatus, CONNECT_YOUR_WALLET);
    }
  }, [wallet, setWalletStatus]);
};
