export const NetworkConfiguration = {
  mainnet: {
    networkId: 137,
    CUContractAddress: "0xdC0479CC5BbA033B3e7De9F178607150B3AbCe1f",
    UNIMContractAddress: "0x64060aB139Feaae7f06Ca4E63189D86aDEb51691",
    RBWContractAddress: "0x431cd3c9ac9fc73644bf68bf5691f4b83f9e104f",
    // TerminusContractAddress: "0x99A558BDBdE247C2B2716f0D4cFb0E246DFB697D",
    DarkForestContractAddress: "0x8d528e98A69FE27b11bb02Ac264516c4818C3942",
    ShadowcornContractAddress: "0xa7D50EE3D7485288107664cf758E877a0D351725",
    GOFPContractAddress: "0xDD8bf70a1f3A5557CCaB839E46cAB5533955Da65",
    TerminusGOFPContractAddress: "0x062BEc5e84289Da2CD6147E0e4DA402B33B8f796",
    ADMIN_BADGE_POOL_ID: "13",
  },
  mumbai: {
    networkId: 80001,
    CUContractAddress: "0xc56b664891B5Ee17A0DAE008a3d351886341664C",
    UNIMContractAddress: "0x47d0f0BD94188e3f8c6fF2C0B1Bf7D6D8BED7534",
    RBWContractAddress: "0x4Df452487E6c9d0C3Dc5EB4936244F8572b3F0b6",
    // TerminusContractAddress: "0x040Cf7Ee9752936d8d280062a447eB53808EBc08",
    TerminusContractAddress: "0x19e812EdB24B68A8F3AbF5e4C82d10AfEf1641Db",
    DarkForestContractAddress: "0x41502BE9507A9BFf110901510C008882194DcB11",
    ShadowcornContractAddress: "0x8819CFdb4Fd6Ba0464Ef2283F5F621443B7eC2F4",
    // GOFPContractAddress: "0x306CE1632aB6eF1De5539b6BaCfB2Fb48293B9a0", // Old
    GOFPContractAddress: "0x8b9493d84e70e94ff9EB1385aD0ed632FD5edE13", // New
    TerminusGOFPContractAddress: "0x040Cf7Ee9752936d8d280062a447eB53808EBc08",
    ADMIN_BADGE_POOL_ID: "168",
    // COMMON_SHADOWCORNEGG_POOL_ID: 3,
    // RARE_SHADOWCORNEGG_POOL_ID: 4,
    // MYTHIC_SHADOWCORNEGG_POOL_ID: 5,
  },
};
