// const METAMASK_REJECT_ERROR_CODE = 4001;
// const RECOMMENDED_GAS = 125000000000;

import { StateConfig } from "../config/StateConfig";
import { getCurrentSession, getLastSession } from "./Staking";

// Because attributes from JSON might not always be in a specific order,
// this helper function ensures we're getting the proper values
// Example: getAttribute("attack", jsonData);
// Returns: string
export function getAttribute(name, obj) {
  let _attributes = obj?.attributes;
  if (_attributes) {
    for (let i = 0; i < _attributes.length; i++) {
      if (_attributes[i].trait_type.toLowerCase() === name.toLowerCase()) {
        return _attributes[i].value;
      }
    }
  }

  return null;
}

// Return an array of Shadowcorns based on specific state array
// Example: filterShadowcornsByStateArray(shadowcorns, [StateConfig.won])
// Returns: array
export function filterShadowcornsByStateArray(shadowcorns, stateArr) {
  if (!shadowcorns || stateArr.length === 0) return;

  let _filteredShadowcorns = [];
  shadowcorns.map((shadowcorn) => {
    stateArr.map((state) => {
      if (shadowcorn.state.name === state.name) {
        _filteredShadowcorns.push(shadowcorn);
      }

      return _filteredShadowcorns;
    });

    return _filteredShadowcorns;
  });

  return _filteredShadowcorns;
}

// Retrieve and parses Shadowcorn JSON from base64 encoded string
// Returns: JSON obj
export async function getShadowcornJSON(shadowcornContract, tokenID) {
  let _shadowcornURI = await shadowcornContract.methods
    .tokenURI(tokenID)
    .call();

  // Remove "data:application/json;base64,"
  _shadowcornURI = _shadowcornURI.replace("data:application/json;base64,", "");

  return JSON.parse(atob(_shadowcornURI));
}

export async function getUnstakedShadowcorns(
  shadowcornContract,
  selectedAddress,
  netconfig,
  currentStage,
  session
) {
  if (!shadowcornContract || !selectedAddress || !netconfig) {
    return;
  }
  const _shadowcornBalance = await shadowcornContract.methods
    .balanceOf(selectedAddress)
    .call();

  console.log("_shadowcornBalance: ", _shadowcornBalance);

  let promises = [];
  let _shadowcornArray = [];
  for (let i = 0; i < _shadowcornBalance; i++) {
    promises.push(
      new Promise(async function (resolve) {
        setTimeout(async () => {
          let _shadowcornTokenID = await shadowcornContract.methods
            .tokenOfOwnerByIndex(selectedAddress, i)
            .call();

          let _shadowcornJson = await getShadowcornJSON(
            shadowcornContract,
            _shadowcornTokenID
          );

          // Used to show local looped videos (vs Arweave)
          let _videoSlug = `${getAttribute(
            "Class",
            _shadowcornJson
          ).toLowerCase()}_${getAttribute(
            "Rarity",
            _shadowcornJson
          ).toLowerCase()}`;

          if (currentStage) {
            _shadowcornJson = await getUpdatedUnstakedStates(
              [_shadowcornJson],
              currentStage,
              session
            );

            _shadowcornJson = _shadowcornJson[0];
          }

          if (_shadowcornJson.name !== " ") {
            _shadowcornArray.push({
              ..._shadowcornJson,
              type: "shadowcorn",
              video_slug: _videoSlug,
              // state: StateConfig.unstaked,
            });
          }

          resolve(true);
        }, 150 * i);
      })
    );
  }

  await Promise.allSettled(promises);
  return _shadowcornArray;
}

export async function getStakedShadowcorns(
  GOFPContract,
  selectedAddress,
  session,
  shadowcornContract,
  currentStage
) {
  const _shadowcornBalance = await GOFPContract.methods
    .numTokensStakedIntoSession(session.sessionID, selectedAddress)
    .call();

  let promises = [];
  let _shadowcornArray = [];
  for (let i = 1; i <= _shadowcornBalance; i++) {
    promises.push(
      new Promise(async function (resolve) {
        setTimeout(async () => {
          let tokenId = await GOFPContract.methods
            .tokenOfStakerInSessionByIndex(
              session.sessionID,
              selectedAddress,
              i
            )
            .call();

          let _shadowcornJson = await getShadowcornJSON(
            shadowcornContract,
            tokenId
          );

          // Used to show local looped videos (vs Arweave)
          let _videoSlug = `${getAttribute(
            "Class",
            _shadowcornJson
          ).toLowerCase()}_${getAttribute(
            "Rarity",
            _shadowcornJson
          ).toLowerCase()}`;

          if (currentStage) {
            _shadowcornJson = await getUpdatedStates(
              GOFPContract,
              session,
              currentStage,
              [_shadowcornJson]
            );

            _shadowcornJson = _shadowcornJson[0];
          }

          if (_shadowcornJson.name !== " ") {
            _shadowcornArray.push({
              ..._shadowcornJson,
              type: "shadowcorn",
              video_slug: _videoSlug,
              sessionID: session.sessionID,
            });
          }

          resolve(true);
        }, 150 * i);
      })
    );
  }

  await Promise.allSettled(promises);

  return _shadowcornArray;
}

export async function getPrevStakedShadowcorns(
  GOFPContract,
  selectedAddress,
  shadowcornContract
) {
  let prevStakedArr = [];

  let session = await getCurrentSession(GOFPContract);

  if (!session) {
    session = await getLastSession(GOFPContract);
  }

  for (let i = 1; i < session.sessionID; i++) {
    let _prevStaked = await getStakedShadowcorns(
      GOFPContract,
      selectedAddress,
      { sessionID: i },
      shadowcornContract
    );

    if (_prevStaked.length > 0) {
      _prevStaked.map((corn) => {
        prevStakedArr.push({
          ...corn,
          sessionID: i,
          state: StateConfig.prevStaked,
        });
        return prevStakedArr;
      });
    }
  }
  return prevStakedArr;
}

export async function getAllShadowcorns(
  shadowcornContract,
  wallet,
  netconfig,
  GOFPContract,
  session,
  currentStage
) {
  let unstakedShadowcornArr = await getUnstakedShadowcorns(
    shadowcornContract,
    wallet?.provider?.selectedAddress,
    netconfig,
    currentStage,
    session
  );

  let stakedShadowcornArr = await getStakedShadowcorns(
    GOFPContract,
    wallet?.provider?.selectedAddress,
    session,
    shadowcornContract,
    currentStage
  );

  let prevStakedArr = await getPrevStakedShadowcorns(
    GOFPContract,
    wallet?.provider?.selectedAddress,
    shadowcornContract
  );

  // If no session active, set to Prev Staked
  if (!session.isActive) {
    stakedShadowcornArr.map((corn) => {
      corn.state = StateConfig.prevStaked;
      return corn;
    });
  }

  let _shadowcorns = [
    ...stakedShadowcornArr,
    ...unstakedShadowcornArr,
    ...prevStakedArr,
  ];

  return _shadowcorns;
}

// Returns true if at least one shadowcorn has been staked into the current session
export function anyShadowcornsStaked(shadowcorns) {
  if (!shadowcorns) return false;
  let _state = false;

  shadowcorns.map((s) => {
    if (
      s.state.name === StateConfig.available.name ||
      s.state.name === StateConfig.selected.name
    ) {
      _state = true;
    }

    return _state;
  });

  return _state;
}

// Returns true if at least one shadowcorn has been selected for a door
export function anyShadowcornsSelected(shadowcorns, doorID) {
  if (!shadowcorns) return false;
  let _state = false;

  shadowcorns.map((s) => {
    if (s.state.name === StateConfig.selected.name) {
      if (doorID) {
        if (Number(s.state.selectedDoor) === Number(doorID)) {
          _state = true;
        }
      } else if (s.state.selectedDoor) {
        _state = true;
      }
    }

    return _state;
  });

  return _state;
}

// Check state
// If pathChoice = 0: Lost
// If pathChoice === getCorrectPathForStage: Availabile
// If pathChoiceForCurrent === getCorrectPathForStage: Confirmed
// If prevPathChoice === getCorrectPathForStage && currentStage === last: Won
//
//  1. Available (staked, but no path selected for current stage)
//  2. Confirmed to a door
//  3. Lost (picked wrong door in prev stage)
export async function getUpdatedStates(
  GOFPContract,
  session,
  currentStage,
  shadowcorns
) {
  let pastStage = currentStage - 1;
  if (pastStage <= 0) pastStage = 1;

  let correctPathPrevStage = Number(
    await GOFPContract.methods
      .getCorrectPathForStage(session.sessionID, pastStage)
      .call()
  );

  // let correctPathCurrentStage = Number(
  //   await GOFPContract.methods
  //     .getCorrectPathForStage(session.sessionID, currentStage)
  //     .call()
  // );

  let promises = [];
  let _shadowcorns = [];
  for (let i = 0; i < shadowcorns.length; i++) {
    let shadowcorn = shadowcorns[i];

    if (
      shadowcorn?.state?.name !== StateConfig.unstaked.name ||
      shadowcorn?.state?.name !== StateConfig.prevStaked.name
    ) {
      promises.push(
        new Promise(async function (resolve) {
          setTimeout(async () => {
            let previousPathChoice = Number(
              await GOFPContract.methods
                .getPathChoice(
                  session.sessionID,
                  shadowcorn.token_id,
                  pastStage
                )
                .call()
            );

            let currentPathChoice = Number(
              await GOFPContract.methods
                .getPathChoice(
                  session.sessionID,
                  shadowcorn.token_id,
                  currentStage
                )
                .call()
            );

            if (
              correctPathPrevStage === previousPathChoice ||
              currentStage === 1
            ) {
              if (currentPathChoice > 0) {
                shadowcorn.state = {
                  ...StateConfig.confirmed,
                  selectedDoor: currentPathChoice,
                };
              } else {
                shadowcorn.state = StateConfig.available;
              }
            }

            if (
              (previousPathChoice === 0 ||
                previousPathChoice !== correctPathPrevStage) &&
              currentStage > 1
            ) {
              shadowcorn.state = StateConfig.unavailable;
            }

            if (
              currentStage === session.stages.length + 1 &&
              previousPathChoice === correctPathPrevStage
            ) {
              shadowcorn.state = StateConfig.won;
            }

            resolve(true);
          }, 150 * i);
        })
      );
    }

    if (shadowcorn?.state?.name === StateConfig.unstaked.name) {
      if (currentStage > 1) {
        shadowcorn.state = StateConfig.missed;
      }
    }

    if (shadowcorn?.state?.name === StateConfig.prevStaked.name) {
      shadowcorn.state = StateConfig.prevStaked;
    }

    _shadowcorns.push(shadowcorn);
  }

  await Promise.allSettled(promises);

  return _shadowcorns;
}

// Check state
// If currentStage > 1: Lost
export function getUpdatedUnstakedStates(shadowcorns, currentStage, session) {
  for (let i = 0; i < shadowcorns.length; i++) {
    let shadowcorn = shadowcorns[i];

    if (session.isActive) {
      if (currentStage > 1) {
        shadowcorn.state = StateConfig.missed;
      } else {
        shadowcorn.state = StateConfig.unstaked;
      }
    }

    if (!session.isActive) {
      shadowcorn.state = StateConfig.noSession;
    }
  }

  return shadowcorns;
}

export function updateShadowcorns(
  shadowcorns,
  tokenIDs,
  newState,
  setShadowcorns
) {
  let _shadowcorns = [...shadowcorns];
  _shadowcorns.map((SC) => {
    tokenIDs.map((tokenID, index) => {
      if (Number(SC.token_id) === Number(tokenID)) {
        SC.state = newState;
      }

      return SC;
    });

    return SC;
  });

  setShadowcorns(_shadowcorns);
}
