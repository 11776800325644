import { init } from "@web3-onboard/react";

import injectedModule from "@web3-onboard/injected-wallets";
import coinbaseModule from "@web3-onboard/coinbase";
// import torusModule from "@web3-onboard/torus";
//import trezorModule from "@web3-onboard/trezor";
//import ledgerModule from "@web3-onboard/ledger";
import walletConnectModule from "@web3-onboard/walletconnect";

import CULogo from "../../img/logo.png";

//New version requires modules to be imported for each wallet
const injected = injectedModule();
const coinbase = coinbaseModule();
//const ledger = ledgerModule();
// const torus = torusModule();
const walletConnect = walletConnectModule();

// const trezorOptions = {
//   email: "contact@laguna.games",
//   appUrl: "https://app.cryptounicorns.fun/",
// };

// const trezor = trezorModule(trezorOptions);

export const initWeb3Onboard = init({
  //Wallets display in the order of this array
  wallets: [coinbase, injected, walletConnect],
  //New version requires each chain that the app supports
  //to be defined
  chains: [
    {
      id: "0x89",
      token: "MATIC",
      label: "Polygon",
      rpcUrl: "https://polygon-rpc.com",
    },
    {
      id: "0x13881",
      token: "MATIC",
      label: "Mumbai",
      rpcUrl: "https://matic-mumbai.chainstacklabs.com",
    },
  ],
  appMetadata: {
    name: "Crypto Unicorns",
    icon: CULogo,
    logo: CULogo,
    description: "Crypto Unicorns Homepage",
    recommendedInjectedWallets: [
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
      { name: "MetaMask", url: "https://metamask.io" },
    ],
  },
  accountCenter: {
    desktop: {
      position: "topRight",
      enabled: false,
      minimal: false,
    },
  },
  i18n: {
    en: {
      connect: {
        selectingWallet: {
          header: "🦄 Welcome to the Crypto Unicorns App",
          sidebar: {
            heading: "",
            subheading: "Select your wallet from the options to get started",
            paragraph: "Crypto Unicorns recommends the Coinbase Wallet.",
          },
        },
      },
    },
  },
});
