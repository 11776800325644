import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Collapse,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import GridDoor from "./GridDoor";
import {
  anyShadowcornsStaked,
  anyShadowcornsSelected,
  filterShadowcornsByStateArray,
} from "../helpers/Shadowcorns";
import { batchConfirmAll } from "../helpers/SelectDoor";
import { StateConfig } from "../config/StateConfig";
import { SessionConfig } from "../config/SessionConfig";

import img_header from "../img/header.jpg";
import "../scss/main.scss";

function ModalDoor({
  shadowcorns,
  doorID,
  handleShadowcornClick,
  showModal,
  handleModalClose,
  currentSession,
  GOFPContract,
  wallet,
  setShadowcorns,
  doorCount,
  currentStage,
  sessionID,
}) {
  const [disableButton, setDisableButton] = useState(false);
  const [showLore, setShowLore] = useState(true);

  useEffect(() => {
    setDisableButton(false);
  }, [showModal]);

  const handleConfirmDoor = async () => {
    setDisableButton(true);

    try {
      // let _confirmedTokenIDs = await batchConfirmDoor(
      await batchConfirmAll(
        GOFPContract,
        currentSession?.sessionID,
        wallet,
        shadowcorns,
        doorCount,
        setShadowcorns
      );

      // await updateShadowcorns(
      //   shadowcorns,
      //   _confirmedTokenIDs,
      //   { ...StateConfig.confirmed, selectedDoor: doorID },
      //   setShadowcorns
      // );
    } catch (err) {
      setDisableButton(false);
    }

    setDisableButton(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      size="xl"
      centered
      className="modal-door">
      <Modal.Header closeButton className="justify-content-center py-3">
        <img className="bg-header" src={img_header} alt="" />
        <Container>
          <Row className="d-flex align-items-center flex-column flex-lg-row">
            <Col>
              <h2 className="text-center text-lg-start">Door {doorID}</h2>
              <h5 className="font-primary text-center text-lg-start">
                Lock Shadowcorns to doors
              </h5>
            </Col>
            <Col className="d-flex justify-content-center justify-content-lg-end">
              <Button
                variant="secondary"
                className="btn-small me-lg-2 my-2 my-lg-0"
                onClick={() => setShowLore(!showLore)}
                aria-controls="collapse-lore"
                aria-expanded={showLore}>
                {showLore ? "Hide" : "Show"} Lore
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body className="pt-0">
        {currentSession?.sessionID && currentStage && (
          <>
            <Collapse in={showLore}>
              <Row id="collapse-lore">
                <Col md={{ span: 10, offset: 1 }} className="text-left">
                  <div className="well">
                    <div className="well-body dark pt-4 pb-1">
                      <h4 className="mb-2 font-primary">
                        {
                          SessionConfig.sessions[sessionID - 1].stage[
                            currentStage
                          ].lore.question
                        }
                      </h4>
                      <figure>
                        <blockquote className="blockquote">
                          <p>
                            {
                              // TODO: Update sessions to sessionID (not 1)
                              SessionConfig.sessions[sessionID - 1].stage[
                                currentStage
                              ].lore.answers[doorID - 1]
                            }
                          </p>
                        </blockquote>
                        <figcaption className="blockquote-footer">
                          Discord Member{" "}
                          <cite title="Source Title">
                            {" "}
                            {
                              // TODO: Update sessions to sessionID (not 1)
                              SessionConfig.sessions[sessionID - 1].stage[
                                currentStage
                              ].lore.authors[doorID - 1]
                            }
                          </cite>
                        </figcaption>
                      </figure>
                      <p></p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Collapse>
          </>
        )}
        <Row>
          <Col md={{ span: 12, offset: 0 }} className="text-center">
            <GridDoor
              showStaked
              shadowcorns={filterShadowcornsByStateArray(shadowcorns, [
                StateConfig.available,
                StateConfig.selected,
                StateConfig.confirmed,
              ])}
              handleClick={(shadowcorn) => handleShadowcornClick(shadowcorn)}
              doorID={doorID}
              currentSession={currentSession}
            />
          </Col>
        </Row>
      </Modal.Body>
      {shadowcorns && currentSession && (
        <Modal.Footer className="justify-content-center pt-3 pb-2">
          <img className="bg-footer" src={img_header} alt="" />
          <Container className="d-flex align-items-center justify-content-center">
            <Row>
              <Col>
                {anyShadowcornsStaked(shadowcorns) ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`modal-btn-tooltip`}>
                        Careful, this will confirm all selected Shadowcorns on
                        all doors
                      </Tooltip>
                    }>
                    <button
                      className="btn btn-primary btn-primary-ingame"
                      disabled={
                        !anyShadowcornsSelected(shadowcorns, doorID) ||
                        disableButton
                      }
                      onClick={handleConfirmDoor}>
                      {`Confirm All Selections`}
                    </button>
                  </OverlayTrigger>
                ) : (
                  <button
                    className="btn btn-primary btn-primary-ingame"
                    onClick={() => {
                      handleModalClose();
                      // document.querySelector(".main").scrollIntoView({
                      //   behavior: "smooth",
                      // });
                    }}>
                    Close
                  </button>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ModalDoor;
