import { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";

import video_intro from "../videos/bg_intro.webm";

function Landing({ performanceMode, selectedAddress, loaded }) {
  const videoBGRef = useRef(null);
  const [dots, setDots] = useState("...");

  const removeElement = (element) => {
    if (typeof element === "string") {
      element = document.querySelector(element);
      element.parentNode.removeChild(element);
    }
    return;
  };

  useEffect(() => {
    let count = 0;
    setInterval(() => {
      count++;

      if (count > 3) {
        count = 0;
      }

      setDots(".".repeat(count));
    }, 750);
  }, []);

  useEffect(() => {
    if (performanceMode) {
      videoBGRef.current.pause();
    } else {
      videoBGRef.current.play();
    }
  }, [performanceMode]);

  useEffect(() => {
    if (!loaded) return;

    let tl = gsap.timeline({ ease: "power1.out" });
    tl.to(".landing", {
      autoAlpha: 0,
      duration: 1,
      delay: 1,
    }).call(removeElement, ["#landing"], "+=3");
  }, [loaded]);

  return (
    <div className="landing" id="landing">
      <video
        ref={videoBGRef}
        muted
        loop
        autoPlay={!performanceMode}
        preload="auto"
        className="layer bg current">
        <source src={video_intro} type="video/webm" />
        Your browser does not support the video tag.
      </video>

      <div className="text">
        {selectedAddress ? (
          <>
            <h1 className="mb-4 d-flex flex-column w-100">
              <span className="font-darkforest">Loading{dots}</span>
            </h1>
          </>
        ) : (
          <>
            <h1 className="mb-4 d-flex flex-column">
              <span>Can you escape the</span>{" "}
              <span className="font-darkforest">Dark Forest?</span>
            </h1>
            <h2>Connect your wallet to begin</h2>
          </>
        )}
      </div>
    </div>
  );
}

export default Landing;
