export const StateConfig = {
  unstaked: {
    name: "unstaked",
    title: "Assign to Chapter",
    subtitle: "",
  },
  prevStaked: {
    name: "prevStaked",
    title: "Assigned to Past Chapter",
    subtitle: "Assigned to Past Chapter",
  },
  available: {
    name: "available",
    title: "Available",
    subtitle: "Select Door ",
  },
  selected: {
    name: "selected",
    title: "Selected Door ",
    subtitle: "Remove Selection",
    selectedDoor: null,
  },
  confirmed: {
    name: "confirmed",
    title: "Confirmed on Door ",
    subtitle: "Good luck",
    selectedDoor: null,
  },
  unavailable: {
    name: "unavailable",
    title: "Ghostcorn",
    subtitle: "Resurrected next Chapter",
    selectedDoor: null,
  },
  missed: {
    name: "missed",
    title: "Missed Assignment Window",
    subtitle: "Resurrected next Chapter",
  },
  won: {
    name: "won",
    title: "Corn-gratulations!",
    subtitle: "You've found the way out",
    selectedDoor: null,
  },
  noSession: {
    name: "noSession",
    title: "No Active Chapter",
    subtitle: "Wait for next Chapter",
  },
};
