import { useEffect } from "react";
import Web3 from "web3";

import ShadowcornABI from "../ShadowcornABI.json";
import GofpABI from "../CU_GOFP_ABI.json";
import TerminusABI from "../TerminusABI.json";

const Contracts = (
  _wallet,
  _netconfig,
  _setShadowcornContract,
  _setGOFPContract,
  _setTerminusContract
) => {
  useEffect(() => {
    if (!_wallet || !_netconfig) return;
    let _web3 = new Web3(_wallet.provider);

    let _shadowcornContract = new _web3.eth.Contract(
      ShadowcornABI,
      _netconfig.ShadowcornContractAddress
    );
    _setShadowcornContract(_shadowcornContract);

    let _GofpContract = new _web3.eth.Contract(
      GofpABI,
      _netconfig.GOFPContractAddress
    );
    _setGOFPContract(_GofpContract);

    let _TerminusContract = new _web3.eth.Contract(
      TerminusABI,
      _netconfig.TerminusGOFPContractAddress
    );
    _setTerminusContract(_TerminusContract);
  }, [_wallet, _netconfig, _setShadowcornContract, _setGOFPContract]);
};
export default Contracts;
