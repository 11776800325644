export const SessionConfig = {
  sessions: {
    1: {
      stage: [
        {
          stage: 0,
          world: "intro",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
        {
          stage: 1,
          world: "fire",
          lore: {
            question: "Who are the Shadowcorns?",
            answers: [
              "Shadowcorns are ancient creatures born from the deepest of the darkness. Heinous, corrupt and wicked, what lies within the heart of a shadowcorn is pure malice and wrath. Always seeking harm in all possible ways, Shadowcorns are restless and boundless to perform evil deeds at any given time.",
              "Implied by its name, Shadowcorns are the dark figures or entities cast upon a surface by the unicorns intercepting the rays from their light source. The Shadowcorns devour their enemies' brilliance and hope to reclaim their stolen glory.",
            ],
            authors: ["ChickenPorkAdobo#3233", "Shikam#9461"],
          },
        },
        {
          stage: 2,
          world: "slime",
          lore: {
            question: "How do the Unicorns and the Shadowcorns differ?",
            answers: [
              "Unicorns are free creatures, doing whatever they want, even invading the realms of the shadowcorns. We on the other hand, only wanted to protect what is left of our race. We only wanted to live in peace here in the dark forest, until the unicorns came and stole from us.",
              "Some say Shadowcorns were born from the essence of Unicorns. The Unicorns that got lost in the darkness. Thus Shadowcorns were born from the despair of the Unicorns.",
              "Unicorns are shiny, good looking creatures. Shadowcorns are scary looking, bad-ass and powerful evil creatures. A single shadowcorn is equivalent to armies of unicorns.",
            ],
            authors: ["ChickenPorkAdobo#3233", "Choakz#0417", "Kylaxie#4863"],
          },
        },
        {
          stage: 3,
          world: "soul",
          lore: {
            question: "Why do the Shadowcorns hate the Unicorns?",
            answers: [
              "The Shadowcorns came from the lost Unicorns which were left by the other Unicorns in the Dark Forest. The Unicorns which were left to rot and die in the Dark Forest felt despair and the power of the Solar Eclipse turned them into Shadowcorns.",
              "The Shadowcorns hated them for they were the reason why the deep and peaceful slumber of the shadowcorns were disrupted. The creatures of the dark was peacefully living inside the dark forest until the Unicorns came, invaded the dark realm, and stole the shadowcorn eggs.",
              "In the grand scheme of things Shadowcorns are just entities to balance, innately passive to the unicorns. But the repeated recklessness and arrogance of the unicorns caused a lot of disparity to the world. Alas the this disparity must be balanced and thus the shadowcorn's retribution came.",
            ],
            authors: [
              "Choakz#0417",
              "ChickenPorkAdobo#3233",
              "urinalbawl#8569",
            ],
          },
        },
        {
          stage: 4,
          world: "nebula",
          lore: {
            question: "What does The Dark Forest mean to Shadowcorns?",
            answers: [
              "The Dark Forest is the birthplace of the Shadowcorns. It is the start and the end. The Shadowcorns shall rise from the Dark Forest and remain there, occasionally reaching out thru the shadows that lurk on every inch of land there is.",
              "The Dark Forest – the place where it all started. This place represents the embodiment of being a Shadowcorn. It is our place of glory, our safe haven. The place that bore witness to the coming of our powers, to all our failures and successes. Yes, this is indeed our home.",
              "Empower our wickedness when we are here. No unicorns can defeat us and our powers are limitless. This is the shadowcorn's playground!",
              "The Dark Forest does not discriminate, Common, Rare or Mythic Shadowcorns—all are welcome… except Unicorns!",
            ],
            authors: [
              "Choakz#0417",
              "ChickenPorkAdobo#3233",
              "Kylaxie#4863",
              "therealcupkate✨#7814",
            ],
          },
        },
        {
          stage: 5,
          world: "volt",
          lore: {
            question: "What is the Shadowcorn Creed?",
            answers: [
              "Bring forth the Fire that gave birth, the energy that Volt surged forward, a Slime that creeped on the floor, whose Souls hovered over the moor, where Nebula let it’s darkness shine over.",
              "Darkness is the only divinity. Shadows are the true apostles.",
              "Annihilate the Unicorn race. Bring destruction to every corner of the multiverse. Revel in carnage and bring forth total destruction. Purge and punish, all for the greater evil.",
              "Born from the shadows, we return to the darkness; the Fire that never lights, Volt that never sparks, Slime that never gleams, Soul that never glows, and Nebula that never shines. Apart, the darkness consumed us. As one, chaos was born out of us.",
              "We vow to make the multiverse the worst place. Everything hereon will be black and full of disgrace. No unicorns can thrive living a prosperous life while we - the Shadowcorns - are still alive.",
            ],
            authors: [
              "Choakz#0417",
              "fatfativan#3604",
              "ChickenPorkAdobo#3233",
              "Choakz#0417",
              "Kylaxie#4863",
            ],
          },
        },
        {
          stage: 6,
          world: "treasure",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
      ],
    },
    2: {
      stage: [
        {
          stage: 0,
          world: "intro",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
        {
          stage: 1,
          world: "volt",
          lore: {
            question: "What happened at the dawn of the Multiverse?",
            answers: [
              "It was all primal darkness at the beginning. The multiverse came from nothingness, and we Shadowcorns feed from that power. No light can enter the realm, and we all have immeasurable power.",
              "It all started with nothing but pure darkness - an eerie and dreadful black space that expanded to every corner of the horizon. Then came the lightning and everything was chaos. Different forms of elements – fire, water, earth, and air, came and poured the void, and the multiverse was born.",
            ],
            authors: ["Kylaxie#4863", "ChickenPorkAdobo#3233"],
          },
        },
        {
          stage: 2,
          world: "soul",
          lore: {
            question: "Why do the islands of our Multiverse float?",
            answers: [
              "Floating islands emanate the true prowess of the Shadowcorns. With unimaginable power forged in the Altar, it provides limitless energy to levitate all magical lands apart from our dark forest realm.",
              "The inherent magic of the Unicorns makes it possible for the islands to float. Their lives weren't theirs, and neither was the life of the objects around them, making the lands an extension of their being.",
              "Islands float because their density is lesser than the volume of surrounding elements; the downward pressure of their weight is less than the upward pressure of its surrounding at that depth, so given the islands' composition and structure, this allows them to float in the air.",
            ],
            authors: ["Kylaxie#4863", "Choakz#0417", "gunter01#6396"],
          },
        },
        {
          stage: 3,
          world: "fire",
          lore: {
            question: "What was the Shadowcorns' great empire like?",
            answers: [
              "The Shadowcorn empire encompassed beauty in all its splendor. Lavas formed into tall pillars; slime aggressively flowed like rivers and a myriad of lurking eyes of the creatures in the dark. Alas! To us Shadowcorns, this place was our paradise. But to the Unicorns, our empire was their worst nightmare.",
              "The Shadowcorn's empire was a den full of fierce and savage creatures of the multiverse. They sensed and fed on the weak and the faint-hearted. The empire has death-chilling auras that were felt across the multiverse.",
              "The Shadowcorns' empire was where dark power always mattered. You needed to be both rugged and creative to survive. The great empire was always in controlled chaos.",
            ],
            authors: ["ChickenPorkAdobo#3233", "Kylaxie#4863", "GDVerse#6031"],
          },
        },
        {
          stage: 4,
          world: "slime",
          lore: {
            question:
              "What's the relationship of Shadowcorns and Unicorns like in the beginning?",
            answers: [
              "They were once good friends, like family, born out of the same entity until one took advantage of the other and started the endless cycle of hatred. Now they are the yin-and-yang of the multiverse. Like water and oil, their relationship's immiscible and will never be whole again.",
              "The very first Shadowcorn and Unicorn were twins. They are so magical that they didn't need to breed. However, when they discovered UNIM, in addition to the birth of greed, they went to battle against each other. The loser of which would be cursed to stop reproducing.",
              "Shadowcorns are the superior creatures in the multiverse, and the unicorns are their humble keeper of the light. Due to their uncontrollable and tremendous power, the Shadowcorns became consumed by nothingness and were doomed for so long that no one could remember.",
              "The Shadowcorns were superior beings, the true masters of the planes - the Chosen Ones.",
            ],
            authors: [
              "ChickenPorkAdobo#3233",
              "therealcupkate✨#7814",
              "Kylaxie#4863",
              "Choakz#0417",
            ],
          },
        },
        {
          stage: 5,
          world: "nebula",
          lore: {
            question: "What acts destroyed the great empire?",
            answers: [
              "The Unicorns became too greedy and knew the Shadowcorns possessed unparalleled power, so they wanted it for themselves. They discovered their eggs and stole them, harvested them, and found out the essence within gave them power, albeit short-lived. They called this Unicorn Milk.",
              "Day by day, unicorns siphoned the resources of the Shadowcorn forests like there was no tomorrow - one of which was an essence for balance, which the unicorns coined Unicorn Milk. The imbalance withered the Shadowcorn forests, and with the immeasurable greed and stupidity of the Unicorns, the empire crumbled.",
              "Unicorns constantly pillaged milk and prideful Shadowcorns cared nothing about it. Eventually, everything added up until Shadowcorns took notice, but it was too late to save the great empire.",
              "The despicable unicorn uses the ancient remnants of the mythical crystal to make the mythical land with the workshop. It can slowly absorb the dark energy of the empire's maintenance. Time has proved that this is feasible.",
              "The Unicorns destroyed the balance. They judged others and thought themselves superior, ignoring the warnings passed down from the ages. Eventually, the Shadowcorns realized their only way to restore balance was to retreat and wait for the right time to act.",
            ],
            authors: [
              "Choakz#0417",
              "urinalbawl#8569",
              "GDVerse#6031",
              "aaa25699#7840",
              "BroccDaRock#6208",
            ],
          },
        },
        {
          stage: 6,
          world: "treasure",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
      ],
    },
    3: {
      stage: [
        {
          stage: 0,
          world: "intro",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
        {
          stage: 1,
          world: "soul",
          lore: {
            question: "What do the Shadowcorns call the Cataclysm?",
            answers: [
              'We Shadowcorns call it "The Great Boon"',
              'We call this "The Great Discontinuity"',
            ],
            authors: ["ChickenPorkAdobo#3233", "Kylaxie#4863"],
          },
        },
        {
          stage: 2,
          world: "nebula",
          lore: {
            question: "What was our plan against the Unicorns?",
            answers: [
              "After all those years of the unicorns' greediness, we started to scout for their only source of UNIM and snatched them one by one. Once it ran out, they couldn't breed anymore, and that's the time we will conquer all their lands in one swoop.",
              "The time has come to unleash our true power. We'll release ravenous magic in their unicorn milk. Once used for breeding, it will turn baby unicorns into our minions, and materials crafted with unicorn milk will turn into weapons of dark energy - sucking the light out of them.",
              "We will orchestrate a grand parade – one that they will not see as a threat. We will divide their numbers accordingly from weakest to strongest, children to adults. Then when they are no longer unified, that’s when we will attack. We will start feeding on the souls of the weakest.",
            ],
            authors: ["Shikam#9461", "Kylaxie#4863", "ChickenPorkAdobo#3233"],
          },
        },
        {
          stage: 3,
          world: "volt",
          lore: {
            question: "What marked the start of the Cataclysm?",
            answers: [
              "The moment the sun was overshadowed by thick black clouds mid-day; when every triumphant laugh was swallowed into stillness; when a Unicorn cry broke the deafening silence. Ah! Yes!! That’s when the Cataclysm started. The beginning of the Unicorns’ nightmare and the mark of the revenge of us Shadowcorns.",
              "One evening a portal to an unknown dark forest emerged. Foolish unicorns entered it out of curiosity and came upon to an unfamiliar liquid. Half-wittedly, drawn by its bewitching sweet scent, they began drinking the liquid. Unbeknownst to them one by one unicorns began to disappear and thus it began.",
              "Then there emerge overflowing pools of unicorn milk scattered over the unicorn lands. It was a never before seen resources that made unicorns drooling to gather all the resources. The moment they felt greed will be their downfall.",
            ],
            authors: [
              "ChickenPorkAdobo#3233",
              "urinalbawl#8569",
              "Kylaxie#4863",
            ],
          },
        },
        {
          stage: 4,
          world: "fire",
          lore: {
            question: "Which battle do these echoes come from?",
            answers: [
              "The Great Carnage. Shadowcorns finally unleashed years upon years of pent up anger towards the Unicorns and stampeded out of the Dark Forest. The Unicorns, were clobbered by the powerful Shadowcorns, receiving the fury brought about by despair, misery and pain.",
              "It was called The Great Strife. Echoes of agony and pain resounds the air, filling it with cries of the Unicorns and menacing laughters of the Shadowcorns. Thus the The Great Strife has been coined as the start of war between the Unicorns and Shadowcorns - the Cataclysm itself.",
              "It was called The Butchering of the Unwary. One of the greatest battle led by the Shadowcorns. Seemingly the unicorns are winning. Shadowcorns are pushed back but Alas it was a tactical retreat. They didn't notice it was leading into a pincer attack. A lot was vanquished that day.",
              "Unicorns reproduce too fast, land resources are not enough, start a war against shadows, there is no right or wrong, just to survive",
            ],
            authors: [
              "Choakz#0417",
              "ChickenPorkAdobo#3233",
              "urinalbawl#8569",
              "aaa25699#7840",
            ],
          },
        },
        {
          stage: 5,
          world: "slime",
          lore: {
            question:
              "What dark device did the Shadowcorns use that the Unicorns never saw coming",
            answers: [
              "The shadowcorns called forth the mighty powers of the dark side deep within the realms of the dark forest. Darkness oozed and seeped into the lands empowering and strengthening the shadowcorns but leaving the unicorns powerless against them.",
              "Deceipt was the main weopon of shadowcorns. Battle after battle the unicorns celebrated thinking they are winning. However they are being played into the traps of the Shadowcorns. Naturally foolish unicorns are no match for the wits of the Shadowcorns.",
              "It's in their blood all along, forging Fire, volt, slime, soul and nebula's blood will create a powerful dark serum weakening unicorn milk that brought life and power to unicorns.",
              "Because of the arrogance of unicorns, they rashly attacked the homeland of shadow unicorns, and I don't know that these environments are a disaster for them, because they are not suitable for contracting the virus",
              "The shadowcorns called forth the mighty powers of the dark side deep within the realms of the dark forest. Darkness oozed and seeped into the lands empowering and strengthening the shadowcorns but leaving the unicorns powerless against them.",
            ],
            authors: [
              "gunter01#6396",
              "urinalbawl#8569",
              "Kylaxie#4863",
              "aaa25699#7840",
              "IslandGurl#4832",
            ],
          },
        },
        {
          stage: 6,
          world: "treasure",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
      ],
    },
    4: {
      stage: [
        {
          stage: 0,
          world: "intro",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
        {
          stage: 1,
          world: "soul",
          lore: {
            question: "“Recount the first day of the Cataclysm?”",
            answers: [
              "Shadowcorns were sucked into an unknown vortex and were teleported to a portal pathway that shows shadowcorns mighty victories. This activated the inner energies from each shadowcorns and started emitting dark auras across the multiverse. Recent bred babies turned minions and crafted items were wielding darkness across the multiverse.",
              "At the first day, the brilliant Shadowcorns sent scout minions to traverse the area. They are fodders to check the strength of the unicorns. During the end of the day almost all of the minions sent were annihilated. However major defense tactics of the unicorns were known.",
            ],
            authors: ["Kylaxie#4863", "urinalbawl#8569"],
          },
        },
        {
          stage: 2,
          world: "volt",
          lore: {
            question: "What happened on the 2nd Day of Cataclysm?",
            answers: [
              "After darkness cover the multiverse, it is the Fire Shadowcorns turn to show its strength. Releasing tiny sparks that the naked eye can't see. Everything that breathes were decimated and turned to ashes. Only unicorns with mythic eyes can detect the sparks coming from Fire Shadowcorn.",
              "On the second day, thunder roared the sky. Sharp flashes of lightning strikes everywhere, sending the Unicorns in panic, tossing some of them off the floating islands for who knows what awaits them at the bottom. The lightning pulverized half of the defenses the unicorns have built, leaving them bare.",
              "The lands crashed down on the surface breaking apart. The once glorious lands have turned into rubble. The Shadowcorns seizing this opportunity, started to rebuild the empire that was once lost. Each class of Shadowcorn ventured to capture a section to create their natural habitat.",
            ],
            authors: ["Kylaxie#4863", "ChickenPorkAdobo#3233", "Choakz#0417"],
          },
        },
        {
          stage: 3,
          world: "fire",
          lore: {
            question: "What happened on the 3rd Day of Cataclysm?",
            answers: [
              "Proving they are restless and are supreme in terms of raw power, the fire Shadowcorns dominated the multiverse on the third day, adding forth fiery destruction and heating up the Cataclysm. The ground shook, cracks appeared and the earth spewed tons of lava, roasting the Unicorns that were caught off-guard.",
              "The Shadowcorns got busy building their new empire. You can see rivers of fire, flowing streams of deadly slime, poles of electromagnetic fields, a mansion surrounded by graveyards and a mysterious section no one can decipher.",
              "The Fire Shadowcorns was sent at the third day. They burned the surroundings and turned it into a scorched earth and a river of magma. Now that there are no avaialable resources that the Unicorns can get. The actual attack comes next.",
            ],
            authors: [
              "ChickenPorkAdobo#3233",
              "Choakz#0417",
              "urinalbawl#8569",
            ],
          },
        },
        {
          stage: 4,
          world: "nebula",
          lore: {
            question: "What happened on the 4th Day of Cataclysm?",
            answers: [
              "As if playing with the already tired and wounded unicorns, the Nebula Shadowcorn took over. They manipulated space, throwing everything off-grid. One Unicorn managed to par with Nebula’s power so it decided to sacrifice its life to call on the biggest asteroid it can muster - all for Shadowcorn’s glory!",
              "Playful as they are, they were shocked that all the events that happened is caused by one naughty Shadowcorn",
              "Darkness emerged as the Nebula Shadowcorns enveloped the surroundings. And then a massive bolt of lightning striked the center of the Unicorns camp. A deafening blast of thunder ruptured the ears of the unicorn. Volt Shadowcorns emerged.The unicorns run in different directions because of the chaos. The shadowcorns charged.",
              "Yesterday was a lucky day since they could move freely even though they went through hell. This time, they cried while trying to move just so they could avoid every danger in the space. They saw the asteroid, closed their eyes, and hoped that they would survive another day.",
            ],
            authors: [
              "ChickenPorkAdobo#3233",
              "GDVerse#6031",
              "urinalbawl#8569",
              "Shikam#9461",
            ],
          },
        },
        {
          stage: 5,
          world: "slime",
          lore: {
            question: "What happened on the Final Day of Cataclysm?",
            answers: [
              "They had no idea that their fallen comrades on each door had reverted to eggs. The eggs were toughened depending on the environment. The Shadowcorns that survived were really the unlucky ones; they died due to lack of goal. The eggs, however, are just waiting to be hatched again.",
              "The Shadowcorns for a while lived in peace. However without a common enemy, they eventually turned against themselves, divided by class. Another war erupted, it became a battle royale.",
              "All shadowcorns have special & powerful abilities unique to them, not knowing that their special abilities is the other class weakness. Consume by power & greed, when unleash also decimate the other class and it was all too late before knowing.",
              "The Shadowcorns did not foresee the effects of Cataclysm. The destruction was too much, too deep it reached the core of the multiverse, and the world began crumbling. Everything collapsed, and everything that is breathing started to perish, including the Shadowcorns who thought they have won the war.",
              "Due to the continual clash the land can no longer hold and began cracking. The Shadowcorns are now disturbed on what's happening. The world is now collapsing. The hatred of the Shadowcorns became now fear and distraught. Blinded by wrath at first but now they are also suffering the same fate.",
            ],
            authors: [
              "Shikam#9461",
              "Choakz#0417",
              "Kylaxie#4863",
              "ChickenPorkAdobo#3233",
              "urinalbawl#8569",
            ],
          },
        },
        {
          stage: 6,
          world: "treasure",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
      ],
    },
    5: {
      stage: [
        {
          stage: 0,
          world: "intro",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
        {
          stage: 1,
          world: "volt",
          lore: {
            question: "Describe the aftermath of the Cataclysm.",
            answers: [
              "The world once populated by Unicorns and Shadowcorns alike had become wasted and barren. Lands no longer floated in the sky. Empires were reduced to ashes. It was like life there had been sucked dry.",
              "It was a total destruction in the multiverse. Shadowcorns and Unicorns were both affected by the cataclysm and    everybody was mournful. There were no signs of life and no hope can be found.",
            ],
            authors: ["Choakz#0417", "Kylaxie#4863"],
          },
        },
        {
          stage: 2,
          world: "slime",
          lore: {
            question: "“For how long was the Multiverse frozen in time?”",
            answers: [
              "Eons have passed silently in the Multiverse. The aftermath of the war brought years upon years of tranquility. Only the gust of wind, cracking sounds of the earth, and splashes of waves in the ocean were heard during this time. Until such time life started to flourish again.",
              "The Multiverse was frozen in like forever. It was a long forgotten time that no one can remember. A destruction no one can remember.",
              "The Multiverse was frozen for hundreds of centuries. Giving way to new life that would eventually evolve to be the Metaverse Pioneers.",
            ],
            authors: ["ChickenPorkAdobo#3233", "Kylaxie#4863", "Choakz#0417"],
          },
        },
        {
          stage: 3,
          world: "soul",
          lore: {
            question: "What contraption did they make?",
            answers: [
              "The Unicorns invented the Gathering Cart. They started exploring the new realm they had just discovered. One day, a Unicorn egg was found while they were gathering. Everyday they managed to find a new egg, until the very last of the 10,000 Genesis Eggs was found. ",
              "They built a goodness- hatred oscillating device. To ensure that no signs of evil in the surrounding. And to their surprise they found those eggs with overflowing goodness.",
              "In order to inherit and continue the race, they built 10,000 genetic eggs at the end of the disappearance. These eggs are of special material and need to be hatched with love, and a small independent space in the legend, only kind people can find the entrance",
            ],
            authors: ["ChickenPorkAdobo#3233", "Kylaxie#4863", "aaa25699#7840"],
          },
        },
        {
          stage: 4,
          world: "nebula",
          lore: {
            question: "What message did they leave for discoverers?",
            answers: [
              "With this new life will come forth the rise of a new civilization. Take care of them well, they are only the ones to conquer the impending return of the vengeful spirits.",
              "“Here sits the remainder of our kin, the last of our hopes. Raise them with love and care; guide them as they discover their abilities as well. Our duty ends here, and so theirs begin - for one day, the Shadowcorns, our great foes, shall awaken again.”",
              "From a cataclysmic time we were wiped out. Our future lies in 10,000 found. What was once a mythical nation. Now lies at the hands of our future saviors.",
              'There stamped an encrypted phrase "in aeternum vive" A sign that goodness will never perish in the multiverse forever.',
            ],
            authors: [
              "urinalbawl#8569",
              "ChickenPorkAdobo#3233",
              "Choakz#0417",
              "Kylaxie#4863",
            ],
          },
        },
        {
          stage: 5,
          world: "fire",
          lore: {
            question: "What did they say when the 1st 'Corn hatched?",
            answers: [
              "“Eggs with……horns?” “…and hundreds of them.” “No, that’s gotta be thousands.” “What did we stumble upon?”",
              "They are so chubby and cute . Some of them looks powerful !",
              '"Uwuuuuu, shiiiinyyyy!!!!"',
              "Wow, the goddess of luck is favored, it is actually such a cute mythical unicorn, um, nurture the offspring well, and restore the previous glory",
              "It's so Fluffy I'm gonna die!",
            ],
            authors: [
              "Choakz#0417",
              "OnnGusta | Lev0x#4358",
              "ChickenPorkAdobo#3233",
              "aaa25699#7840",
              "BroccDaRock#6208",
            ],
          },
        },
        {
          stage: 6,
          world: "treasure",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
      ],
    },
    6: {
      stage: [
        {
          stage: 0,
          world: "intro",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
        {
          stage: 1,
          world: "fire",
          lore: {
            question: "What is to be done to the Unicorns?",
            answers: [
              "We would show them who owns the loots they are stealing. Quality over quantity!",
              "We shall take their legendary mythic's head as war trophy and display in the dark forest entrance. Poison all their unim and spread radiation of death into every lands they have. Dark clouds to never leave the multiverse from now on.",
            ],
            authors: ["Shikam#9461", "Kylaxie#4863"],
          },
        },
        {
          stage: 2,
          world: "slime",
          lore: {
            question: "what rituals do Shadowcorns do on the eve of battle?",
            answers: [
              "Shadowcorns believe that true power lies within them. So they inflict wounds and drink their spirited blood and does the howling uWu chant. They mark their body with their own blood.",
              "They made big noise with their hooves and took out a big golden barrel which is full of dark spirits. All got  got pumped and buffed, unleashing their darkness",
              "They bring out the sacred ichor, their Milk passed down by their elders. Each of the Shadowcorns take a sip to unlock their powers within. Unleashing Common, Rare and Mythical powers that lie dormant.",
            ],
            authors: ["Kylaxie#4863", "GDVerse#6031", "Choakz#0417"],
          },
        },
        {
          stage: 3,
          world: "volt",
          lore: {
            question: "What is the ultimate flaw of the Unicorns?",
            answers: [
              "Unicorns ultimate flaw is their energy system. Using their special abilities drains so much energy and re-energizing it requires days. The reason they need to eat more berries to re-energize faster  but in return makes them fat coz of so much eating.",
              "The Unicorns' Achilles Heel is their limited energy regeneration. Even though they possess great powers, it comes at the cost of their limited energy; thus making it their greatest flaw. While we Shadowcorns do not have such weakness and we will come at them when they least expect it.",
              "The ultimate flaw of Unicorns is their dependency on UNIM. Conquering the main source will definitely make them bow down to us.",
            ],
            authors: ["Kylaxie#4863", "ChickenPorkAdobo#3233", "Shikam#9461"],
          },
        },
        {
          stage: 4,
          world: "nebula",
          lore: {
            question: "What did we call the champions of our kind?",
            answers: [
              "We called our chanpions Shodow Bringers.",
              "We were the bringers of chaos. But those who they feared the most were The Ultimate Shadowcorns!",
              "They are called the Ascended",
              "immortal",
            ],
            authors: [
              "neo_star#2357",
              "Choakz#0417",
              "RXIS#2606",
              "ArtToRich#9336",
            ],
          },
        },
        {
          stage: 5,
          world: "soul",
          lore: {
            question: "What is the war cry of the Shadowcorns?",
            answers: [
              "EAT! HUNT! KILL! EAT! HUNT! KILL! EAT! HUNT! KILL!",
              "Take back what's ours! Take back what's ours! Take back what's ours!",
              "UwU UwU UwU!!!",
              "Uuuuuuuuuuuwuuuuuuuuuuuuuuugh!!",
              "RAAAAAH !! RAAAAAH !! RAID !!!!",
            ],
            authors: [
              "GDVerse#6031",
              "Shikam#9461",
              "ChickenPorkAdobo#3233",
              "Choakz#0417",
              "QueenFox#9574",
            ],
          },
        },
        {
          stage: 6,
          world: "treasure",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
      ],
    },
    7: {
      stage: [
        {
          stage: 0,
          world: "intro",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
        {
          stage: 1,
          world: "treasure",
          lore: {
            question: "",
            answers: [""],
            authors: [""],
          },
        },
      ],
    },
  },
};
