import Web3 from "web3";
import { NetworkConfiguration } from "../config/NetworkConfig";
import { StateConfig } from "../config/StateConfig";
import { updateShadowcorns } from "../helpers/Shadowcorns";

import ERC20ABI from "../CU_ERC20_ABI.json";
import ShadowcornABI from "../ShadowcornABI.json";
import GOFPABI from "../CU_GOFP_ABI.json";
// import { updateShadowcorns } from "./Shadowcorns";

const RECOMMENDED_GAS = 125000000000;
const METAMASK_REJECT_ERROR_CODE = 4001;
// const RBW_COST = 100;

export async function getCurrentSession(GOFPContract) {
  let _numSessions = await GOFPContract.methods.numSessions().call();
  // console.log("_numSessions: ", _numSessions);

  for (let i = 1; i <= _numSessions; i++) {
    let _session = await GOFPContract.methods.getSession(i).call();

    if (_session.isActive) {
      // console.log({ ..._session, sessionID: i });
      return { ..._session, sessionID: i };
    }
  }
}

export async function getLastSession(GOFPContract) {
  let _numSessions = await GOFPContract.methods.numSessions().call();

  let _session = await GOFPContract.methods.getSession(_numSessions).call();

  return { ..._session, sessionID: Number(_numSessions) };
}

async function checkAllowanceAndApprove(
  _item,
  _RBWContract,
  _wallet,
  _netconfig,
  _SCContract,
  _currentSession
) {
  let _status = true;
  const _rbwAllowance = await _RBWContract.methods
    .allowance(_wallet?.accounts[0]?.address, _netconfig.GOFPContractAddress)
    .call();
  console.log("RBW: ", Web3.utils.fromWei(_rbwAllowance, "ether"));

  // Need to adjust this number based on the price
  if (
    Number(Web3.utils.fromWei(_rbwAllowance, "ether")) <
    Number(_currentSession.paymentAmount)
  ) {
    _status = false;
    await _RBWContract.methods
      .approve(
        _netconfig.GOFPContractAddress,
        Web3.utils.toWei("10000000", "ether")
      )
      .send({
        from: _wallet?.accounts[0]?.address,
        transactionBlockTimeout: 500,
        transactionPollingTimeout: 1500,
        gasPrice: RECOMMENDED_GAS,
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
      })
      .on("receipt", function (receipt) {
        if (!receipt.status) {
          console.log(
            "RBW Approval transaction was reverted on the blockchain."
          );
        } else {
          console.log("RBW Approval success!");
          _status = true;
        }
      })
      .on("error", function (err) {
        if (err.code !== METAMASK_REJECT_ERROR_CODE) {
          // _item.state.pending = false;
        }
        console.log(err);
        return false;
      });
  }

  const _SCAllowance = false;

  let _approved = await _SCContract.methods
    .isApprovedForAll(
      _wallet?.accounts[0]?.address,
      _netconfig.GOFPContractAddress
    )
    .call();

  if (!_SCAllowance && _item.length === 1 && !_approved) {
    _status = false;
    await _SCContract.methods
      .approve(_netconfig.GOFPContractAddress, _item[0].token_id)
      .send({
        from: _wallet?.accounts[0]?.address,
        transactionBlockTimeout: 500,
        transactionPollingTimeout: 1500,
        gasPrice: RECOMMENDED_GAS,
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
      })
      .on("receipt", function (receipt) {
        if (!receipt.status) {
          console.log(
            "Shadowcorn Approval transaction was reverted on the blockchain."
          );
        } else {
          console.log("Shadowcorn approval success!");
          _status = true;
        }
      })
      .on("error", function (err) {
        if (err.code !== METAMASK_REJECT_ERROR_CODE) {
          _item.state.pending = false;
        }
        console.log(err);
        return false;
      });
  }
  if (!_SCAllowance && _item.length > 1 && !_approved) {
    _status = false;
    await _SCContract.methods
      .setApprovalForAll(_netconfig.GOFPContractAddress, true)
      .send({
        from: _wallet?.accounts[0]?.address,
        transactionBlockTimeout: 500,
        transactionPollingTimeout: 1500,
        gasPrice: RECOMMENDED_GAS,
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
      })
      .on("receipt", function (receipt) {
        if (!receipt.status) {
          console.log(
            "Shadowcorns Approval transaction was reverted on the blockchain."
          );
        } else {
          console.log("Shadowcorns approval success!");
          _status = true;
        }
      })
      .on("error", function (err) {
        if (err.code !== METAMASK_REJECT_ERROR_CODE) {
          _item.state.pending = false;
        }
        console.log(err);
        return false;
      });
  }

  return _status;
}

// async function handleStaking(
//   _RBWContract,
//   _wallet,
//   _netconfig,
//   _SCContract,
//   _GOFPContract,
//   _item,
//   _currentSession,
//   setButtonDisabled,
//   handleModalClose
// ) {
//   let _approved = await checkAllowanceAndApprove(
//     _item,
//     _RBWContract,
//     _wallet,
//     _netconfig,
//     _SCContract,
//     _currentSession
//   );

//   if (_approved) {
//     await _GOFPContract.methods
//       .stakeTokensIntoSession(_currentSession.sessionID, [_item.token_id])
//       .send({
//         from: _wallet?.accounts[0]?.address,
//         transactionBlockTimeout: 500,
//         transactionPollingTimeout: 1500,
//         gasPrice: RECOMMENDED_GAS,
//       })
//       .on("transactionHash", function (hash) {
//         console.log(hash);
//       })
//       .on("receipt", function (receipt) {
//         setButtonDisabled(false);
//         console.log(receipt);
//         if (!receipt.status) {
//           console.log("Transaction was reverted on the blockchain.");
//         } else {
//           _item.state = StateConfig.available;
//           handleModalClose();
//           console.log("success!");
//         }
//       })
//       .on("error", function (err) {
//         console.log(err);
//         // Skip error msg if user rejected (Error code 4001)
//         if (err.code !== METAMASK_REJECT_ERROR_CODE) {
//           // _item.state.pending = false;
//         }
//         setButtonDisabled(false);
//       });
//   }
// }

export async function stakeIntoSession(
  wallet,
  items,
  currentSession,
  setButtonDisabled,
  handleModalClose,
  allItems,
  setShadowcorns
) {
  const netconfig = NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];
  // item.state.pending = true;
  setButtonDisabled(true);

  let web3 = new Web3(wallet.provider);
  web3.eth.transactionBlockTimeout = 100;
  web3.eth.transactionPollingTimeout = 1500;

  let RBWContract = new web3.eth.Contract(
    ERC20ABI,
    netconfig.RBWContractAddress
  );

  let SCContract = new web3.eth.Contract(
    ShadowcornABI,
    netconfig.ShadowcornContractAddress
  );

  let GOFPContract = new web3.eth.Contract(
    GOFPABI,
    netconfig.GOFPContractAddress
  );

  // item.state = StateConfig.available;

  // await handleStaking(
  //   RBWContract,
  //   wallet,
  //   netconfig,
  //   SCContract,
  //   GOFPContract,
  //   item,
  //   currentSession,
  //   setButtonDisabled
  // );
  //item.state.pending = true;
  //item.state = StateConfig.available;

  let _approved = await checkAllowanceAndApprove(
    items,
    RBWContract,
    wallet,
    netconfig,
    SCContract,
    currentSession
  );

  let tokenIDs = [];
  items.map((SC) => {
    tokenIDs.push(SC.token_id);
    return tokenIDs;
  });

  if (_approved) {
    await GOFPContract.methods
      .stakeTokensIntoSession(currentSession.sessionID, tokenIDs)
      .send({
        from: wallet?.accounts[0]?.address,
        transactionBlockTimeout: 500,
        transactionPollingTimeout: 1500,
        gasPrice: RECOMMENDED_GAS,
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
      })
      .on("receipt", function (receipt) {
        setButtonDisabled(false);
        console.log(receipt);
        if (!receipt.status) {
          console.log("Transaction was reverted on the blockchain.");
        } else {
          // items.map((item) => {
          //   item.state = StateConfig.available;
          //   return item;
          // });
          updateShadowcorns(
            allItems,
            tokenIDs,
            StateConfig.available,
            setShadowcorns
          );

          handleModalClose();
          console.log("success!");
        }
      })
      .on("error", function (err) {
        console.log(err);
        // Skip error msg if user rejected (Error code 4001)
        if (err.code !== METAMASK_REJECT_ERROR_CODE) {
          // _item.state.pending = false;
        }
        setButtonDisabled(false);
      });
  }
}

export async function unstakeAll(wallet, session, shadowcorns) {
  const netconfig = NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];

  let web3 = new Web3(wallet.provider);

  let GOFPContract = new web3.eth.Contract(
    GOFPABI,
    netconfig.GOFPContractAddress
  );

  let _currentStage = await GOFPContract.methods
    .getCurrentStage(session.sessionID)
    .call();

  const updateState = (unstakeTokenIDArr) => {
    // return getUpdatedUnstakedStates(shadowcorns, _currentStage);

    shadowcorns.map((SC) => {
      unstakeTokenIDArr.map((id) => {
        if (SC.token_id === id) {
          if (_currentStage > 1) {
            SC.state = StateConfig.missed;
          } else {
            SC.state = StateConfig.unstaked;
          }

          if (!session.isActive) {
            SC.state = StateConfig.noSession;
          }
        }
        return SC;
      });

      return SC;
    });
  };

  let _number;
  if (session.isActive) {
    _number = session.sessionID;
  }
  if (!session.isActive) {
    _number = session.sessionID + 1;
  }

  for (let i = 1; i < _number; i++) {
    let unstakeTokenIDArr = [];
    shadowcorns.map((SC) => {
      if (SC.sessionID === i) {
        unstakeTokenIDArr.push(SC.token_id);
      }

      return SC;
    });

    if (unstakeTokenIDArr.length > 0) {
      await GOFPContract.methods
        .unstakeTokensFromSession(i, unstakeTokenIDArr) //session#, [tokenId's]
        .send({
          from: wallet?.accounts[0]?.address,
          transactionBlockTimeout: 500,
          transactionPollingTimeout: 1500,
          gasPrice: RECOMMENDED_GAS,
        })
        .on("transactionHash", function (hash) {
          console.log(hash);
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          if (!receipt.status) {
            console.log("Transaction was reverted on the blockchain.");
          } else {
            updateState(unstakeTokenIDArr);
            console.log("success!");
          }
        })
        .on("error", function (err) {
          console.log(err);
          // Skip error msg if user rejected (Error code 4001)
          if (err.code !== METAMASK_REJECT_ERROR_CODE) {
          }
        });
    }
  }
}

export async function unstakeSingle(shadowcorn, wallet) {
  const netconfig = NetworkConfiguration[process.env.REACT_APP_POLYGON_NETWORK];

  let web3 = new Web3(wallet.provider);

  let GOFPContract = new web3.eth.Contract(
    GOFPABI,
    netconfig.GOFPContractAddress
  );

  await GOFPContract.methods
    .unstakeTokensFromSession(shadowcorn.sessionID, [shadowcorn.token_id]) //session#, [tokenId's]
    .send({
      from: wallet?.accounts[0]?.address,
      transactionBlockTimeout: 500,
      transactionPollingTimeout: 1500,
      gasPrice: RECOMMENDED_GAS,
    })
    .on("transactionHash", function (hash) {
      console.log(hash);
    })
    .on("receipt", function (receipt) {
      console.log(receipt);
      if (!receipt.status) {
        console.log("Transaction was reverted on the blockchain.");
      } else {
        shadowcorn.state = StateConfig.unstaked;
        console.log("success!");
      }
    })
    .on("error", function (err) {
      console.log(err);
      // Skip error msg if user rejected (Error code 4001)
      if (err.code !== METAMASK_REJECT_ERROR_CODE) {
      }
    });
}
