import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
//import Web3 from "web3";

// Components
import Nav from "./components/Nav";
import Landing from "./components/Landing";
import Hero from "./components/Hero";
import Divider from "./components/Divider";
import Shadowcorns from "./components/Shadowcorns";
import ModalDoor from "./components/ModalDoor";
import ModalAdmin from "./components/ModalAdmin";

// Helpers + Configs
import { SessionConfig } from "./config/SessionConfig";
import { StateConfig } from "./config/StateConfig";
import { getCurrentSession, getLastSession } from "./helpers/Staking";
import { getAllShadowcorns, getUpdatedStates } from "./helpers/Shadowcorns";

// Wallet-specific Imports
import { NetworkConfiguration } from "./config/NetworkConfig";
import ConnectWallet from "./components/ConnectWallet";
import {
  POLYGON_NETWORK,
  CONNECT_YOUR_WALLET,
  WalletHook,
} from "./components/ConnectWallet/WalletHelper";
import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import Contracts from "./helpers/ContractHelper";

import "./scss/main.scss";

function App() {
  const [shadowcornContract, setShadowcornContract] = useState(null);
  const [GOFPContract, setGOFPContract] = useState(null);
  const [terminusContract, setTerminusContract] = useState(null);

  const [{ wallet }, connect] = useConnectWallet();
  // const [web3, setWeb3] = useState(null);
  const [walletStatus, setWalletStatus] = useState(CONNECT_YOUR_WALLET);
  const [{ connectedChain }] = useSetChain();

  const [showModalDoor, setShowModalDoor] = useState(false);
  // const handleModalDoorOpen = () => setShowModalDoor(true);
  const handleModalDoorClose = () => setShowModalDoor(false);

  // const [showModalRules, setShowModalRules] = useState(false);
  // const handleModalRulesOpen = () => setShowModalRules(true);
  // const handleModalRulesClose = () => setShowModalRules(false);

  const [showModalAdmin, setShowModalAdmin] = useState(false);
  const handleModalAdminOpen = () => setShowModalAdmin(true);
  const handleModalAdminClose = () => setShowModalAdmin(false);

  const [loaded, setLoaded] = useState(false);
  const [performanceMode, setPerformanceMode] = useState(false);
  const [revealDoorID, setRevealDoorID] = useState(null);
  const [correctDoorID, setCorrectDoorID] = useState(null);
  const [selectedDoorID, setSelectedDoorID] = useState(null);
  const [lockDoors, setLockDoors] = useState(false);

  const [shadowcorns, setShadowcorns] = useState(null);

  const [doorCount, setDoorCount] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [currentStage, setCurrentStage] = useState(null);

  const [adminBadge, setAdminBadge] = useState(false);

  const [transitionWorlds, setTransitionWorlds] = useState({
    triggerTransition: false,
    current: null,
    next: null,
  });

  ConnectWallet({ wallet, connect });

  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  if (!netconfig) {
    console.error(
      `Unsupported polygon network: ${POLYGON_NETWORK}. Valid choices: mainnet or mumbai.`
    );
  }

  WalletHook(wallet, netconfig, connectedChain, setWalletStatus);

  Contracts(
    wallet,
    netconfig,
    setShadowcornContract,
    setGOFPContract,
    setTerminusContract
  );

  //Checking for admin badge

  const getAdminStatus = useCallback(async () => {
    let balance = await terminusContract.methods
      .balanceOf(
        wallet?.provider?.selectedAddress,
        netconfig.ADMIN_BADGE_POOL_ID
      )
      .call();

    if (balance > 0) {
      setAdminBadge(true);
    }
    if (balance === 0) {
      setAdminBadge(false);
    }
  }, [wallet, terminusContract, netconfig.ADMIN_BADGE_POOL_ID]);

  // *************
  // LOAD BLOCKCHAIN INFO
  // Shadowcorns, Door count, World style
  // *************
  useEffect(() => {
    const getData = async () => {
      if (
        !shadowcornContract ||
        !GOFPContract ||
        !netconfig ||
        !wallet?.provider?.selectedAddress
      )
        return;

      let _session = await getCurrentSession(GOFPContract);
      console.log("session (on load): ", _session);

      // Check if no active session. Used to display SCs for folks to unstake
      if (!_session) {
        _session = await getLastSession(GOFPContract);
      }

      // # of doors for current sessions
      let _currentStage = await GOFPContract.methods
        .getCurrentStage(_session.sessionID)
        .call();
      _currentStage = Number(_currentStage);
      console.log("currentStage: ", _currentStage);

      // Store current stage as prevStage to be used later to detect when stages have changed (only for non-refresh)
      localStorage.setItem("prevStage", _currentStage);

      setCurrentSession({ ..._session, currentStage: _currentStage });
      setCurrentStage(_currentStage);

      // Check if choosing is active
      _session?.isChoosingActive ? setLockDoors(false) : setLockDoors(true);

      let _allShadowcorns = await getAllShadowcorns(
        shadowcornContract,
        wallet,
        netconfig,
        GOFPContract,
        _session,
        _currentStage
      );
      console.log("_allShadowcorns: ", _allShadowcorns);
      setShadowcorns(_allShadowcorns);
      // Session + Stage
      await getWorldState();

      // World (fire, volt, etc)
      let _nextStage = _currentStage + 1;
      if (_nextStage > _session.stages.length) _nextStage = _currentStage; // Shim to make sure we don't go beyond

      // Check if last stage to queue up treasure room
      let _currentWorld =
        SessionConfig.sessions[_session.sessionID - 1].stage[_currentStage]
          .world;
      let _nextWorld =
        SessionConfig.sessions[_session.sessionID - 1].stage[_nextStage].world;
      if (_currentStage === _nextStage) {
        // _currentWorld = "treasure";
        _nextWorld = "treasure";
      }

      setTransitionWorlds((transitionWorlds) => ({
        ...transitionWorlds,
        current: _currentWorld,
        next: _nextWorld,
      }));

      // Set door count
      setDoorCount(Number(_session.stages[_currentStage - 1])); // Stages are 1 indexed

      // Reveal loaded world
      setLoaded(true);
    };

    const getWorldState = async () => {
      if (!GOFPContract) return;

      let _session = await getCurrentSession(GOFPContract);

      // Check if no active session. Used to display SCs for folks to unstake
      if (!_session) {
        _session = await getLastSession(GOFPContract);
      }
      setCurrentSession(_session);

      // Check if choosing is active
      if (!_session?.isChoosingActive) {
        setLockDoors(true);
      }

      // # of doors for current sessions
      let _currentStage = await GOFPContract.methods
        .getCurrentStage(_session.sessionID)
        .call();
      setCurrentStage(_currentStage);

      // Check if stage has changed to a new world while we were on the page
      let _prevStage = localStorage.getItem("prevStage");
      if (Number(_prevStage) !== Number(_currentStage) && _prevStage) {
        // Get correct path for current stage
        let _correctDoor = await GOFPContract.methods
          .getCorrectPathForStage(_session.sessionID, _prevStage)
          .call();

        setLockDoors(true);

        setCorrectDoorID(_correctDoor);
      }
    };

    setInterval(() => {
      getWorldState();
    }, 5000); // Update every 5 seconds

    getData();
  }, [
    shadowcornContract,
    GOFPContract,
    netconfig,
    wallet?.provider?.selectedAddress,
    wallet,
  ]);

  // *************
  // CHECK FOR ADMIN BADGE
  // *************
  //  Running in own useEffect because this was causing
  //    issues running in the one above
  useEffect(() => {
    if (!wallet || !terminusContract || !netconfig) return;
    getAdminStatus();
  }, [getAdminStatus, wallet, terminusContract, netconfig]);

  // *************
  // RESET STATE ON NEW WORLD
  // *************
  useEffect(() => {
    const updateStates = async () => {
      if (!GOFPContract || !currentSession || !currentStage || !shadowcorns)
        return;

      // Set door count
      setDoorCount(Number(currentSession.stages[currentStage - 1])); // Stages are 1 indexed

      // let _session = await getCurrentSession(GOFPContract);
      // let _currentStage = await GOFPContract.methods
      //   .getCurrentStage(_session.sessionID)
      //   .call();

      let _shadowcorns = shadowcorns;
      if (transitionWorlds.triggerTransition) {
        _shadowcorns = await getUpdatedStates(
          GOFPContract,
          currentSession,
          currentStage,
          _shadowcorns
        );
      }

      setLockDoors(false);

      setShadowcorns(_shadowcorns);
    };

    updateStates();
  }, [
    // GOFPContract,
    // currentSession,
    // shadowcorns,
    // currentStage,
    // transitionWorlds,
    transitionWorlds.triggerTransition,
  ]);

  // *************
  // UPDATE STATE ON SHADOWCORN SELECT
  // *************
  const handleShadowcornClick = (shadowcorn) => {
    switch (shadowcorn.state.name) {
      case "available":
        shadowcorn.state = {
          ...StateConfig.selected,
          selectedDoor: selectedDoorID,
        };
        break;
      case "selected":
        shadowcorn.state = {
          ...StateConfig.available,
          selectedDoor: null,
        };
        break;
      default:
        console.log("App.js:handleShadowcornClick DEFAULT CASE -- handle");
    }

    // Update Shadowcorn array item
    // setShadowcornsStaked((prevShadowcorns) =>
    setShadowcorns((prevShadowcorns) =>
      prevShadowcorns.map((prevCorn) =>
        prevCorn.token_id === shadowcorn.token_id ? { ...shadowcorn } : prevCorn
      )
    );
  };

  return (
    <>
      <div>
        <Nav
          walletStatus={walletStatus}
          walletOnClick={() => connect()}
          adminOnClick={() => handleModalAdminOpen()}
          setPerformanceMode={setPerformanceMode}
          adminBadge={adminBadge}
        />
        <Landing
          performanceMode={performanceMode}
          selectedAddress={wallet?.provider?.selectedAddress}
          loaded={loaded}
        />
        <Hero
          shadowcorns={shadowcorns}
          doorCount={doorCount}
          setSelectedDoorID={setSelectedDoorID}
          setShowModalDoor={setShowModalDoor}
          revealDoorID={revealDoorID}
          setRevealDoorID={setRevealDoorID}
          performanceMode={performanceMode}
          transitionWorlds={transitionWorlds}
          setTransitionWorlds={setTransitionWorlds}
          lockDoors={lockDoors}
          adminBadge={adminBadge}
          // sessionID={Number(currentSession?.sessionID)}
          sessionID={1} // TODO: Remove and flip the sessionID above
          currentStage={Number(currentStage)}
        />
        <div className="main">
          <Divider
            revealDoorID={revealDoorID}
            setRevealDoorID={setRevealDoorID}
            wallet={wallet}
            shadowcorns={shadowcorns}
            GOFPContract={GOFPContract}
            currentSession={currentSession}
            currentStage={currentStage}
            doorCount={doorCount}
            setLockDoors={setLockDoors}
            correctDoorID={correctDoorID}
            setShadowcorns={setShadowcorns}
            adminBadge={adminBadge}
          />
          <Container>
            <Row>
              <Col>
                <div className="section-shadowcorns">
                  <Shadowcorns
                    currentSession={currentSession}
                    currentStage={currentStage}
                    shadowcorns={shadowcorns}
                    wallet={wallet}
                    setShadowcorns={setShadowcorns}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <ModalDoor
        currentSession={currentSession}
        sessionID={Number(currentSession?.sessionID)}
        currentStage={Number(currentStage)}
        shadowcorns={shadowcorns}
        doorID={selectedDoorID}
        showModal={showModalDoor}
        handleModalClose={handleModalDoorClose}
        handleShadowcornClick={handleShadowcornClick}
        GOFPContract={GOFPContract}
        wallet={wallet}
        setShadowcorns={setShadowcorns}
        doorCount={doorCount}
      />

      <ModalAdmin
        currentSession={currentSession}
        currentStage={currentStage}
        wallet={wallet}
        GOFPContract={GOFPContract}
        showModal={showModalAdmin}
        handleModalClose={handleModalAdminClose}
        numberOfDoors={doorCount}
      />
    </>
  );
}

export default App;
