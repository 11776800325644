// const METAMASK_REJECT_ERROR_CODE = 4001;
// const RECOMMENDED_GAS = 125000000000;

export const toggleActiveSession = async (contract, wallet, sessionID) => {
  // Get current Session state
  const _sessionState = await contract.methods.getSession(sessionID).call();

  console.log(
    "Admin.js#toggleActiveSession._sessionState: ",
    _sessionState.isActive
  );

  const _sessionToggle = await contract.methods
    .setSessionActive(sessionID, !_sessionState.isActive)
    .send({ from: wallet?.provider?.selectedAddress });
  return _sessionToggle;
};

export const toggleActiveChoosing = async (contract, wallet, sessionID) => {
  // Get current Session state
  const _sessionState = await contract.methods.getSession(sessionID).call();

  console.log(
    "Admin.js#toggleActiveStage._sessionState.isChoosingActive: ",
    _sessionState.isChoosingActive
  );

  try {
    const _sessionToggle = await contract.methods
      .setSessionChoosingActive(sessionID, !_sessionState.isChoosingActive)
      .send({ from: wallet?.provider?.selectedAddress });
    return _sessionToggle;
  } catch (error) {
    return false;
  }
};

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
