import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { StateConfig } from "../config/StateConfig";
import { SessionConfig } from "../config/SessionConfig";

import video_transition from "../videos/transition.webm";
import Door from "./Door";

const Hero = React.memo(
  ({
    shadowcorns,
    doorCount,
    setShowModalDoor,
    setSelectedDoorID,
    revealDoorID,
    setRevealDoorID,
    performanceMode,
    transitionWorlds,
    setTransitionWorlds,
    lockDoors,
    adminBadge,
    sessionID,
    currentStage,
  }) => {
    const videoBGRef = useRef(null);
    const videoTransitionRef = useRef(null);

    useEffect(() => {
      if (!videoBGRef.current) return;

      if (performanceMode) {
        videoBGRef.current.pause();
      } else {
        videoBGRef.current.play();
      }
    }, [performanceMode]);

    useEffect(() => {
      if (!videoBGRef.current) return;
      videoBGRef.current.pause();
      videoBGRef.current.play();
    }, [transitionWorlds?.current]);

    useEffect(() => {
      if (!revealDoorID) return;

      let _revealDelay = 1 * doorCount + 3; // TODO: Convert magic numbers (3s delay)

      let tl = gsap.timeline({ ease: "power1.out" });
      tl.to(`.door-wrapper-${revealDoorID} .door.correct`, {
        autoAlpha: 1,
        duration: 1,
        delay: _revealDelay,
      }).to(
        `.door-wrapper .door.incorrect`,
        {
          autoAlpha: 1,
          duration: 1,
        },
        ">+0.5"
      );

      // Check if progressed
      let _progressed = false;

      shadowcorns.map((shadowcorn) => {
        if (
          shadowcorn.state.name === StateConfig.confirmed.name &&
          Number(shadowcorn.state.selectedDoor) === Number(revealDoorID)
        ) {
          console.log("progressed matched winning!!");
          _progressed = true;
        }

        return shadowcorn;
      });
      // setProgressed(_progressed);

      setTimeout(() => {
        if (_progressed || adminBadge) {
          videoTransitionRef.current.play();

          setTimeout(() => {
            console.log("swap to new world: ", transitionWorlds.next);
            // Trigger swap to new world
            setTransitionWorlds({
              current: transitionWorlds.next,
              next: null,
              triggerTransition: true,
            });

            // Remove door shake
            setRevealDoorID(null);
          }, 2000); // Time when transision video is covering screen
        } else {
          // No shadowcorns made it :(
          tl.to(`.hero`, {
            "-webkit-filter": "grayscale(1)",
            filter: "grayscale(1)",
            duration: 2,
          }).to(
            `.overlay-transition.lost`,
            {
              autoAlpha: 1,
              duration: 2,
            },
            "-=1.5"
          );
        }

        // TODO: Update magic numbers to const
        // _revealDelay, converted, + 2.5s delay + 0.5s incorrect reveal
      }, _revealDelay * 1000 + 3000);
    }, [revealDoorID]);

    const Doors = React.memo(() => {
      let _doorArr = [];
      for (let i = 1; i <= doorCount; i++) {
        _doorArr.push(
          <Door
            key={i}
            id={i}
            shadowcorns={shadowcorns}
            setShowModalDoor={setShowModalDoor}
            setSelectedDoorID={setSelectedDoorID}
            revealDoorID={revealDoorID}
            performanceMode={performanceMode}
            transitionWorlds={transitionWorlds}
            lockDoors={lockDoors}
          />
        );
      }

      return _doorArr;
    });

    return (
      <div className="hero">
        <div className="doors">
          <Doors />
        </div>
        {/* {sessionID && currentStage && doorCount && (
          <div className="well-purple">
            <h2 className="text">
              {
                SessionConfig.sessions[sessionID].stage[currentStage].lore
                  .question
              }
            </h2>
            <p className="mb-0">Select a door to read the possible answers</p>
          </div>
        )} */}

        {doorCount ? <div className="overlay"></div> : ""}

        {transitionWorlds.current && (
          <video
            ref={videoBGRef}
            key={`current-${transitionWorlds.current}`}
            muted
            loop
            autoPlay={!performanceMode}
            preload="auto"
            className="layer bg current">
            <source
              src={`/videos/bg/bg_${transitionWorlds.current}.webm`}
              type="video/webm"
            />
            <source
              src={`/videos/bg/bg_${transitionWorlds.current}.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}

        {transitionWorlds.next && (
          <video
            key={`next-${transitionWorlds.next}`}
            muted
            loop
            autoPlay={!performanceMode}
            preload="auto"
            className={`layer bg next ${
              transitionWorlds.triggerTransition ? "show" : ""
            }`}>
            <source
              src={`/videos/bg/bg_${transitionWorlds.next}.webm`}
              type="video/webm"
            />
            <source
              src={`/videos/bg/bg_${transitionWorlds.next}.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}

        <div className="overlay-transition">
          <video muted preload="auto" ref={videoTransitionRef}>
            <source src={video_transition} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="overlay-transition lost">
          <h1 className="font-darkforest mb-4">All Shadowcorns Lost</h1>
          <h3>You may try again upon the start of the next chapter</h3>
        </div>
      </div>
    );
  }
);

export default Hero;
