import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import GridUnstaked from "./GridUnstaked";
import GridPrevStaked from "./GridPrevStaked";

import img_header from "../img/header.jpg";
import img_lantern from "../img/lantern.png";

import { StateConfig } from "../config/StateConfig";
import { stakeIntoSession, unstakeAll } from "../helpers/Staking";
import { filterShadowcornsByStateArray } from "../helpers/Shadowcorns";

const Shadowcorns = React.memo(
  ({ currentSession, currentStage, shadowcorns, wallet, setShadowcorns }) => {
    const [showModal, setShowModal] = useState(false);
    const [item, setItem] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleModalClose = () => setShowModal(false);
    const handleModalOpen = (item) => {
      setItem(item);
      setShowModal(true);
    };

    const handleStakeButtonClick = (shadowcorn) => {
      setButtonDisabled(false);
      handleModalOpen(shadowcorn);
      // stakeIntoSession(wallet, shadowcorn, currentSession);
    };

    // const handleUnstakeButtonClick = (shadowcorns) => {
    //   handleModalOpen(shadowcorn);
    //   unstakeSC(wallet, currentSession.sessionID, shadowcornsPrevStaked);
    //   unstakeAll(wallet, currentSession.sessionID, shadowcorns);
    // };

    const handleUnstakeAllButtonClick = () => {
      unstakeAll(wallet, currentSession, shadowcorns);
    };

    const handleStakeAll = () => {
      stakeIntoSession(
        wallet,
        filterShadowcornsByStateArray(shadowcorns, [StateConfig.unstaked]),
        currentSession,
        setButtonDisabled,
        handleModalClose,
        shadowcorns,
        setShadowcorns
      );
    };

    const CustomWell = React.memo(
      ({
        title,
        subtitle,
        shadowcorns,
        handleButtonClick,
        showButtons,
        SpecificGridView,
        handleGlobalButtonClick,
        handleStakeAllButtonClick,
      }) => {
        let _unstaked = filterShadowcornsByStateArray(shadowcorns, [
          StateConfig.unstaked,
        ]);
        return (
          <div className="well mb-5">
            <div className="well-header">
              <Container>
                <Row>
                  <Col>
                    <h2 className="title">{title}</h2>
                    <h5 className="subtitle">{subtitle}</h5>
                  </Col>
                </Row>
              </Container>
              <img className="img-lantern left" src={img_lantern} alt="" />
              <img className="img-lantern right" src={img_lantern} alt="" />
              <img className="bg-header" src={img_header} alt="" />
            </div>
            <div className="well-body">
              <Container>
                {handleGlobalButtonClick &&
                  shadowcorns &&
                  shadowcorns.length > 0 && (
                    <Row>
                      <Col className="text-center">
                        <Button
                          className="btn btn-primary btn-primary-ingame mb-3 w-auto"
                          onClick={() => handleGlobalButtonClick(shadowcorns)}>
                          Unassign All
                        </Button>
                      </Col>
                    </Row>
                  )}
                {handleStakeAllButtonClick &&
                  _unstaked &&
                  _unstaked.length > 0 &&
                  Number(currentStage) === 1 && (
                    <Row>
                      <Col className="text-center">
                        <Button
                          disabled={buttonDisabled}
                          className="btn btn-primary btn-primary-ingame mb-3 w-auto"
                          onClick={() => handleStakeAll(shadowcorns)}>
                          {buttonDisabled ? "Confirming..." : "Assign All"}
                        </Button>
                      </Col>
                    </Row>
                  )}
                <Row>
                  <Col>
                    {wallet?.provider?.selectedAddress ? (
                      <SpecificGridView
                        shadowcorns={shadowcorns}
                        handleClick={handleButtonClick}
                        showButtons={showButtons}
                        currentSession={currentSession}
                        buttonDisabled={buttonDisabled}
                      />
                    ) : (
                      <h2 className="text-center my-5">
                        Connect Wallet to view Shadowcorns
                      </h2>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        );
      }
    );

    return (
      <>
        <CustomWell
          title={"Shadowcorns"}
          shadowcorns={filterShadowcornsByStateArray(shadowcorns, [
            StateConfig.unstaked,
            StateConfig.selected,
            StateConfig.available,
            StateConfig.unavailable,
            StateConfig.confirmed,
            StateConfig.won,
            StateConfig.missed,
            StateConfig.noSession,
          ])}
          handleButtonClick={handleStakeButtonClick}
          SpecificGridView={GridUnstaked}
          handleStakeAllButtonClick={handleStakeAll}
        />
        <br />
        <CustomWell
          title={"Previously Assigned Shadowcorns"}
          subtitle={"Unassign and reassign into current session to play again"}
          shadowcorns={filterShadowcornsByStateArray(shadowcorns, [
            StateConfig.prevStaked,
          ])}
          // handleButtonClick={handleUnstakeButtonClick}
          handleGlobalButtonClick={handleUnstakeAllButtonClick}
          SpecificGridView={GridPrevStaked}
        />

        <Modal show={showModal} onHide={handleModalClose} size="lg" centered>
          <Modal.Header closeButton className="justify-content-center py-3">
            <img className="bg-header" src={img_header} alt="" />
            <Container>
              <Row>
                <Col>
                  <h2>{item?.name}</h2>
                  <h5>{item?.state?.title}</h5>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Row className="align-items-center">
              <Col md={{ span: 5 }}>
                <img
                  src={`/imgs/shadowcorn_${item?.video_slug}.jpg`}
                  className="shadowcorn img-fluid"
                  style={{ borderRadius: "5px" }}
                  alt=""
                />
              </Col>
              <Col md={{ span: 7 }}>
                <h2 className="mt-3">Just checking</h2>
                <h5>
                  Is this Shadowcorn brave enough to escape the Dark Forest?
                  Rewards await those who embark on the perilous adventure of
                  escape.
                </h5>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="justify-content-center pt-3 pb-2">
            <img className="bg-footer" src={img_header} alt="" />
            <Container className="d-flex align-items-center justify-content-center">
              <Row>
                <Col>
                  <button
                    disabled={buttonDisabled}
                    className="btn btn-primary btn-primary-ingame"
                    onClick={() => {
                      stakeIntoSession(
                        wallet,
                        [item],
                        currentSession,
                        setButtonDisabled,
                        handleModalClose,
                        shadowcorns,
                        setShadowcorns
                      );
                    }}>
                    {buttonDisabled ? "Confirming..." : "Confirm Assignment"}
                  </button>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
);

export default Shadowcorns;
