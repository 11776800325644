import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import img_divider from "../img/divider.jpg";
import {
  anyShadowcornsSelected,
  filterShadowcornsByStateArray,
} from "../helpers/Shadowcorns";
import { batchConfirmAll } from "../helpers/SelectDoor";
import { StateConfig } from "../config/StateConfig";
import { SessionConfig } from "../config/SessionConfig";

const Divider = React.memo(
  ({
    revealDoorID,
    setRevealDoorID,
    GOFPContract,
    wallet,
    shadowcorns,
    currentStage,
    currentSession,
    doorCount,
    setLockDoors,
    correctDoorID,
    setShadowcorns,
    adminBadge,
  }) => {
    const [canReveal, setCanReveal] = useState(false);
    const [revealing, setRevealing] = useState(false);

    useEffect(() => {
      if (!currentStage) return;

      // Get any stored stage from localHost
      let prevStage = localStorage.getItem("prevStage");

      if (Number(prevStage) !== Number(currentStage) && prevStage) {
        setCanReveal(true);
        setLockDoors(true);
        // localStorage.setItem("prevStage", currentStage);
      }
    }, [currentStage]);

    const handleReveal = () => {
      setRevealing(true);

      setRevealDoorID(correctDoorID);

      // TODO: Convert magic numbers for timing to global state
      // Currently tied to Hero.js timing
      let _revealDelay = 1 * doorCount + 3; // 3s delay after door shake animations

      // _revealDelay, converted, + 2.5s delay + 0.5s incorrect reveal
      let _transitionPoint = _revealDelay * 1000 + 3000;
      setTimeout(() => {
        // Check if any shadowcorns progressed
        let _progressed = false;
        let _shadowcorns = filterShadowcornsByStateArray(shadowcorns, [
          StateConfig.available,
        ]);
        _shadowcorns.map((shadowcorn) => {
          if (shadowcorn.state.name === StateConfig.available.name) {
            _progressed = true;
          }

          return shadowcorn;
        });

        localStorage.setItem("prevStage", currentStage);

        if (_progressed || adminBadge) {
          // setLockDoors(false);
          // setRevealDoorID(null);
          setRevealing(false);
          setCanReveal(false);
        }
      }, _transitionPoint + 3000); // Delay 3s after middle of transition animation
    };

    return (
      <div className="divider">
        <Container className="d-flex align-items-center justify-content-center">
          <Row>
            <Col>
              {doorCount > 0 ? (
                <>
                  {canReveal ? (
                    <button
                      className="btn btn-primary btn-primary-ingame"
                      disabled={revealing}
                      onClick={() => handleReveal()}>
                      Reveal Correct Door
                    </button>
                  ) : (
                    <div className="text-center">
                      <h2 className="my-0">
                        {
                          // TODO: Replace 1 with currcurrentSession?.sessionIDentSession
                          SessionConfig.sessions[currentSession.sessionID - 1]
                            ?.stage[currentStage]?.lore?.question
                        }
                      </h2>
                      <p className="my-0">
                        Select a door to view the possible answers
                      </p>
                    </div>
                    // <button
                    //   disabled={!anyShadowcornsSelected(shadowcorns)}
                    //   className="btn btn-primary btn-primary-ingame"
                    //   onClick={async () => {
                    //     await batchConfirmAll(
                    //       GOFPContract,
                    //       currentSession?.sessionID,
                    //       wallet,
                    //       filterShadowcornsByStateArray(shadowcorns, [
                    //         StateConfig.selected,
                    //       ]),
                    //       doorCount,
                    //       setShadowcorns
                    //     );
                    //   }}>
                    //   {anyShadowcornsSelected(shadowcorns)
                    //     ? "Confirm All Pending"
                    //     : "Select a door to begin"}
                    // </button>
                  )}
                </>
              ) : (
                <h2>Corn-gratulations to the winners!</h2>
              )}
            </Col>
          </Row>
        </Container>
        <img src={img_divider} alt="" className="bg-divider" />
      </div>
    );
  }
);

export default Divider;
