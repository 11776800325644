import { useState, useEffect } from "react";
import { initWeb3Onboard } from "./Wallets";
import { useWallets } from "@web3-onboard/react";

const ConnectWallet = ({ wallet, connect }) => {
  const connectedWallets = useWallets();
  const [web3Onboard, setWeb3Onboard] = useState(null);

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);

    // Remove styling (for better font rendering)
    // document.getElementsByTagName("onboard-v2")[0].removeAttribute("style");
  }, []);

  //Create local storage item for the users wallet
  useEffect(() => {
    if (!connectedWallets.length) return;

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    );
    window.localStorage.setItem(
      "connectedWallets",
      JSON.stringify(connectedWalletsLabelArray)
    );
  }, [connectedWallets, wallet]);

  //Fetch the most recently used wallet and default connect
  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem("connectedWallets")
    );

    if (previouslyConnectedWallets?.length) {
      async function setWalletFromLocalStorage() {
        await connect({ autoSelect: previouslyConnectedWallets[0] });
      }
      setWalletFromLocalStorage();
    }
  }, [web3Onboard, connect]);
};
export default ConnectWallet;
