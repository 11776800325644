import { Button } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Countdown, { zeroPad } from "react-countdown";

import logo from "../img/logo.png";
import "../scss/main.scss";

function Nav({
  walletStatus,
  walletOnClick,
  adminOnClick,
  setPerformanceMode,
  adminBadge,
}) {
  const HOW_TO_PLAY_URL =
    "https://medium.com/@lagunagames/shadowcorns-throwing-shade-4a887d8737bf";
  // const Completionist = () => <span>You are good to go!</span>;

  // const renderer = ({ hours, minutes, seconds, completed }) => {
  //   if (completed) {
  //     return <Completionist />;
  //   } else {
  //     return (
  //       <div className="countdown d-flex flex-column align-items-center align-items-md-start my-2">
  //         <h5 className="font-primary mb-0">Time until door reveal</h5>
  //         <h3>
  //           {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
  //         </h3>
  //       </div>
  //     );
  //   }
  // };

  return (
    <div className="top-nav">
      <div className="left d-flex flex-md-row flex-column align-items-center align-items-md-center justify-content-center">
        <a
          href="https://www.cryptounicorns.fun"
          target="_blank"
          rel="noreferrer">
          <img className="logo" src={logo} alt="Crypto Unicorns" />
        </a>
        {/* <Countdown date={Date.now() + 10000000} renderer={renderer} /> */}
        <div className="form-check form-switch">
          <label
            title="Performance Mode"
            htmlFor="switch-performance"
            className="form-check-label">
            Performance mode
          </label>
          <input
            type="checkbox"
            id="switch-performance"
            className="form-check-input"
            onChange={(e) => setPerformanceMode(e.target.checked)}
          />
        </div>
      </div>
      <div className="right d-flex flex-md-row flex-column align-items-center align-items-md-center justify-content-center">
        <div className="btn-wrapper d-flex flex-column flex-xl-row align-items-center">
          {adminBadge && (
            <Button
              className="btn btn-secondary btn-small"
              onClick={adminOnClick}>
              Admin
            </Button>
          )}
          <a href={HOW_TO_PLAY_URL} target="_blank" rel="noreferrer">
            <Button className="btn btn-secondary btn-small">How to Play</Button>
          </a>
          <Button
            className="btn-wallet"
            variant="primary"
            onClick={walletOnClick}>
            {walletStatus}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Nav;
