// import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";

import imgChains from "../img/shadowcorn-chains.png";
import { StateConfig } from "../config/StateConfig";
import { filterShadowcornsByStateArray } from "../helpers/Shadowcorns";

import Web3 from "web3";

function GridUnstaked({
  shadowcorns,
  handleClick,
  currentSession,
  buttonDisabled,
}) {
  const ItemGrid = ({ itemArray, handleClick }) => {
    const items = [];

    for (let i = 0; i < itemArray?.length; i++) {
      let _showButton =
        // itemArray[i]?.state.name !== StateConfig.available.name &&
        // itemArray[i]?.state.name !== StateConfig.selected.name &&
        itemArray[i]?.state.name === StateConfig.unstaked.name &&
        currentSession;

      items.push(
        <div
          key={i}
          className={`d-flex flex-column align-items-center justify-content-start item state-${
            itemArray[i]?.state?.name
          } ${_showButton ? "buttons" : ""}`}>
          <div className="top">
            <p className="font-primary my-2">{itemArray[i].name}</p>
          </div>
          <div className="img-wrapper">
            <img src={imgChains} className="shadowcorn-chains" alt="" />
            <img
              src={`/imgs/shadowcorn_${itemArray[i].video_slug}.jpg`}
              className="shadowcorn"
              alt=""
            />
          </div>
          <div className="bottom">
            {_showButton ? (
              <button
                disabled={buttonDisabled}
                className={`btn btn-primary btn-primary-ingame d-flex flex-column align-items-center state-${itemArray[i]?.state?.name}`}
                onClick={() => handleClick(itemArray[i])}>
                <span className="title">
                  {itemArray[i]?.state?.title}
                  {itemArray[i]?.state?.selectedDoor}
                </span>
                <span className="subtitle">
                  {itemArray[i]?.state?.subtitle}
                  {itemArray[i]?.state === StateConfig.unstaked &&
                    `Cost: ${Web3.utils.fromWei(
                      currentSession.paymentAmount
                    )} RBW`}
                </span>{" "}
              </button>
            ) : (
              <p className="font-primary my-2">
                {itemArray[i]?.state?.title}
                {itemArray[i]?.state?.selectedDoor}
              </p>
            )}
          </div>
        </div>
      );
    }

    // if (items.length === 0) {
    //   return (
    //     <h2 className="text-center font-primary my-5 w-100">
    //       No Shadowcorns Found
    //     </h2>
    //   );
    // }

    return items;
  };

  return (
    <>
      {Array.isArray(shadowcorns) && currentSession ? (
        <div className={`item-grid thirds`}>
          {shadowcorns.length !== 0 ? (
            <>
              <ItemGrid
                itemArray={filterShadowcornsByStateArray(shadowcorns, [
                  StateConfig.selected,
                  StateConfig.available,
                  StateConfig.confirmed,
                  StateConfig.won,
                ])}
                handleClick={handleClick}
              />
              <ItemGrid
                itemArray={filterShadowcornsByStateArray(shadowcorns, [
                  StateConfig.unstaked,
                  StateConfig.unavailable,
                  StateConfig.missed,
                  StateConfig.noSession,
                ])}
                handleClick={handleClick}
              />
            </>
          ) : (
            <h2 className="text-center font-primary my-5 w-100">
              No Shadowcorns Found
            </h2>
          )}
          {/* <ItemGrid itemArray={shadowcorns} handleClick={handleClick} /> */}
        </div>
      ) : (
        <div className="my-5 text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <h3 className="mt-2">Loading...</h3>
        </div>
      )}
    </>
  );
}

export default GridUnstaked;
