import { StateConfig } from "../config/StateConfig";

const RECOMMENDED_GAS = 125000000000;
const METAMASK_REJECT_ERROR_CODE = 4001;

export async function batchConfirmAll(
  GOFPContract,
  sessionID,
  wallet,
  shadowcorns,
  doorCount,
  setShadowcorns
) {
  // let doorTotal = 3;
  let _tokenIdArr = [];
  let _pathArr = [];

  const setPath = async () => {
    await GOFPContract.methods
      .chooseCurrentStagePaths(sessionID, _tokenIdArr, _pathArr)
      .send({
        from: wallet?.accounts[0]?.address,
        transactionBlockTimeout: 500,
        transactionPollingTimeout: 1500,
        gasPrice: RECOMMENDED_GAS,
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        if (!receipt.status) {
          console.log("Transaction was reverted on the blockchain.");
        } else {
          console.log("success!");
        }
      })
      .on("error", function (err) {
        console.log(err);
        // Skip error msg if user rejected (Error code 4001)
        if (err.code !== METAMASK_REJECT_ERROR_CODE) {
        }
      });
  };

  const getTokenIdArray = async (doorID) => {
    shadowcorns.map((SC) => {
      if (SC.state.name === "selected" && SC.state.selectedDoor === doorID) {
        _tokenIdArr.push(SC.token_id.toString());
        _pathArr.push(doorID);
      }

      return SC;
    });
  };

  for (let i = 1; i <= doorCount; i++) {
    await getTokenIdArray(i);
  }

  await setPath();

  // Update state to reflect
  let _shadowcorns = [...shadowcorns];
  _shadowcorns.map((shadowcorn) => {
    _tokenIdArr.map((tokenID, index) => {
      if (Number(shadowcorn.token_id) === Number(tokenID)) {
        shadowcorn.state = {
          ...StateConfig.confirmed,
          selectedDoor: _pathArr[index],
        };
      }

      return shadowcorn;
    });

    return shadowcorn;
  });

  setShadowcorns(_shadowcorns);
  return _shadowcorns;
}

export async function batchConfirmDoor(
  GOFPContract,
  sessionID,
  wallet,
  shadowcorns,
  selectedDoor,
  handleModalClose
) {
  // let doorTotal = 3;
  let _tokenIdArr = [];
  let _pathArr = [];

  const setPath = async () => {
    await GOFPContract.methods
      .chooseCurrentStagePaths(sessionID, _tokenIdArr, _pathArr)
      .send({
        from: wallet?.accounts[0]?.address,
        transactionBlockTimeout: 500,
        transactionPollingTimeout: 1500,
        gasPrice: RECOMMENDED_GAS,
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        if (!receipt.status) {
          console.log("Transaction was reverted on the blockchain.");
        } else {
          console.log("success!");
          // handleModalClose();
        }
      })
      .on("error", function (err) {
        console.log(err);
        // Skip error msg if user rejected (Error code 4001)
        if (err.code !== METAMASK_REJECT_ERROR_CODE) {
        }
      });
  };

  const getTokenIdArray = async (selectedDoor) => {
    shadowcorns.map((SC) => {
      if (
        SC.state.name === StateConfig.selected.name &&
        SC.state.selectedDoor === selectedDoor
      ) {
        _tokenIdArr.push(Number(SC.token_id.toString()));
        _pathArr.push(Number(selectedDoor));
      }

      return SC;
    });
  };

  await getTokenIdArray(selectedDoor);

  console.log("shadowcorns: ", shadowcorns);
  console.log("_pathArr: ", _pathArr);
  console.log("_tokenIdArr: ", _tokenIdArr);

  await setPath();
  return _tokenIdArr;
}
