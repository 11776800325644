// import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";

import imgChains from "../img/shadowcorn-chains.png";
// import { StateConfig } from "../helpers/StateConfig";

function GridPrevStaked({
  shadowcorns,
  handleClick,
  showStaked,
  currentSession,
}) {
  const ItemGrid = ({ itemArray, handleClick }) => {
    const items = [];
    for (let i = 0; i < itemArray?.length; i++) {
      // let _showButton = itemArray[i]?.state.name === StateConfig.prevStaked.name && currentSession;
      let _showButton = false;

      items.push(
        <div
          key={i}
          className={`d-flex flex-column align-items-center justify-content-start item state-${
            itemArray[i]?.state?.name
          } ${_showButton ? "buttons" : ""}`}>
          <div className="top">
            <p className="font-primary my-2">{itemArray[i].name}</p>
          </div>
          <div className="img-wrapper">
            <img src={imgChains} className="shadowcorn-chains" alt="" />
            <img
              src={`/imgs/shadowcorn_${itemArray[i].video_slug}.jpg`}
              className="shadowcorn"
              alt=""
            />
          </div>
          <div className="bottom">
            {_showButton ? (
              <button
                className={`btn btn-primary btn-primary-ingame d-flex flex-column align-items-center state-${itemArray[i]?.state?.name}`}
                onClick={() => handleClick(itemArray[i])}>
                <span className="title">
                  {itemArray[i]?.state?.title}
                  {itemArray[i]?.state?.selectedDoor}
                </span>
                <span className="subtitle">
                  {itemArray[i]?.state?.subtitle}
                </span>
              </button>
            ) : (
              <p className="font-primary my-2">
                {itemArray[i]?.state?.title}
                {itemArray[i]?.state?.selectedDoor}
              </p>
            )}
          </div>
        </div>
      );
    }

    if (items.length === 0) {
      return (
        <h2 className="text-center font-primary my-5 w-100">
          {showStaked
            ? "Please assign a shadowcorn into this session"
            : "No shadowcorns found"}
        </h2>
      );
    }

    return items;
  };

  return (
    <>
      {shadowcorns && currentSession ? (
        <div className={`item-grid thirds`}>
          <ItemGrid itemArray={shadowcorns} handleClick={handleClick} />
        </div>
      ) : (
        <div className="my-5 text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <h3 className="mt-2">Loading...</h3>
        </div>
      )}
    </>
  );
}

export default GridPrevStaked;
