import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import door_outline from "../videos/door_outline.png";
import { StateConfig } from "../config/StateConfig";

const Door = React.memo(
  ({
    id,
    shadowcorns,
    // handleModalOpen,
    setShowModalDoor,
    setSelectedDoorID,
    revealDoorID,
    performanceMode,
    transitionWorlds,
    lockDoors,
  }) => {
    const [selectedCount, setSelectedCount] = useState(0);
    const [confirmedCount, setConfirmedCount] = useState(0);
    const [klasses, setKlasses] = useState(null);

    useEffect(() => {
      // setShowDoor(revealDoor);
      if (!revealDoorID) return;

      setInterval(() => {
        setKlasses("shake");
      }, 1000 * id);
    }, [revealDoorID, id]);

    useEffect(() => {
      if (!shadowcorns) return;

      // Loop through shadowcorn array and calculate # of selected doors
      let countSelected = 0;
      shadowcorns.map((corn) => {
        if (
          corn.state?.name === StateConfig.selected.name &&
          corn.state?.selectedDoor &&
          corn.state?.selectedDoor === id
        ) {
          countSelected++;
        }

        return countSelected;
      });

      // Loop through shadowcorn array and calculate # of confirmed doors
      let countConfirmed = 0;
      shadowcorns.map((corn) => {
        if (
          corn.state?.name === StateConfig.confirmed.name &&
          corn.state?.selectedDoor &&
          Number(corn.state?.selectedDoor) === id
        ) {
          countConfirmed++;
        }

        return countConfirmed;
      });

      setSelectedCount(countSelected);
      setConfirmedCount(countConfirmed);
    }, [shadowcorns, id]);

    return (
      <>
        <div
          // className={`door-wrapper ${
          //   Number(id) === Number(revealDoorID) ? "door-wrapper-correct" : ""
          // }`}
          className={`door-wrapper door-wrapper-${id} ${
            lockDoors ? "inactive" : ""
          }`}
          onClick={() => {
            setSelectedDoorID(id);
            setShowModalDoor(true);
          }}>
          <video
            muted
            loop
            autoPlay={!performanceMode}
            preload="auto"
            className={`door option ${klasses}`}
            poster={`videos/doors/posters/door_${transitionWorlds.current}_poster.png`}>
            <source
              src={`videos/doors/door_${transitionWorlds.current}.webm`}
              type="video/webm"
            />
            Your browser does not support the video tag.
          </video>

          {Number(revealDoorID) === Number(id) && (
            <video
              muted
              loop
              autoPlay={!performanceMode}
              preload="auto"
              className={`door correct ${klasses}`}
              poster={`videos/doors/posters/door_${transitionWorlds.current}_correct_poster.png`}>
              <source
                src={`videos/doors/door_${transitionWorlds.current}_correct.webm`}
                type="video/webm"
              />
              Your browser does not support the video tag.
            </video>
          )}

          {/* {revealDoorID !== id && (
          <video
            muted
            loop
            autoPlay={!performanceMode}
            preload="auto"
            className={`door incorrect ${klasses}`}
            poster={`videos/doors/posters/door_${transitionWorlds.current}_incorrect_poster.png`}>
            <source
              src={`videos/doors/door_${transitionWorlds.current}_incorrect.webm`}
              type="video/webm"
            />
            Your browser does not support the video tag.
          </video>
        )} */}

          {/* Shim to fix video resize flicker issue in browser */}
          <img className="door-outline" src={door_outline} alt="" />

          <div className="door-info">
            <div className="door-number">
              <div className="label">Door</div>
              <div className="jumbo">{id}</div>
            </div>
            <div className="well-purple door-well">
              <h4 className="mb-2">
                {confirmedCount} Confirmed
                {selectedCount !== 0 && (
                  <>
                    <span className="font-secondary">{" • "}</span>
                    {`${selectedCount} Pending`}
                  </>
                )}
              </h4>
              {/* <p className="mb-1">0 Confirmed Globally</p> */}
              {lockDoors && <p className="font-primary mb-1">Door locked</p>}
              {/* <p className="mb-1">revealDoor: {revealDoorID}</p> */}
              {confirmedCount > 0 && (
                <p className="mb-1">Lootbox Rewards airdropped!</p>
              )}
              {/* {confirmedCount > 0 && (
                <Button
                  variant="secondary"
                  className="btn-small"
                  style={{ marginBottom: -40, pointerEvents: "all" }}
                  target="_blank"
                  href="https://claim.cryptounicorns.fun"
                  onClick={() => {
                    setTimeout(() => {
                      setShowModalDoor(false);
                    }, 10);
                  }}>
                  View Rewards
                </Button>
              )} */}
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default Door;
